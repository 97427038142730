import { RouteSettlementPayment } from 'noddi-async/src/types';
import { formatCurrencyAmount } from 'noddi-util';

function FutureRoutePayment({ routeSettlement }: { routeSettlement: RouteSettlementPayment }) {
  const estimatedPayment = Math.max(
    parseInt(routeSettlement.performancePayment ?? '0'),
    parseInt(routeSettlement.computedMinimumPayment ?? '0')
  );

  const waitAndDriveTimePayment =
    parseInt(routeSettlement?.waitingTimePayment ?? '0') + parseInt(routeSettlement?.drivingTimePayment ?? '0');
  return (
    <div className='flex flex-col gap-2'>
      <p className='font-semibold'>💵 Estimated Payment details</p>
      <p>
        We think this route will pay <span className='font-bold'>{formatCurrencyAmount(estimatedPayment)}</span>.
      </p>
      <p>
        This is based on a payment for the actuals cars you service on{' '}
        <span className='font-bold'>{formatCurrencyAmount(Number(routeSettlement.sumSalesItemsLevel1Payment))}</span>,
        and payment for driving & waiting of{' '}
        <span className='font-bold'> {formatCurrencyAmount(waitAndDriveTimePayment)}</span>.
      </p>
      <p>
        In addition, the mileage payment is estimated to be{' '}
        <span className='font-bold'> {formatCurrencyAmount(Number(routeSettlement.kilometerPayment))}</span>. Road toll
        cost will be calculated after the route is completed.
      </p>
    </div>
  );
}

function getSalesItemsSummary(routeSettlement: RouteSettlementPayment) {
  return routeSettlement.salesItems.reduce(
    (acc, salesItem) => {
      const exists = acc[salesItem.id];
      if (exists) {
        acc[salesItem.id] = {
          ...salesItem,
          name: salesItem.name,
          count: exists.count + 1
        };
      } else {
        acc[salesItem.id] = {
          name: salesItem.name,
          count: 1,
          carSizes: salesItem.carSizes
        };
      }
      return acc;
    },
    {} as {
      [key: string]: {
        name: string;
        count: number;
        carSizes: { id: number; name: string }[];
      };
    }
  );
}

export const Tasks = ({
  routeSettlement,
  isFutureRoute
}: {
  routeSettlement: RouteSettlementPayment;
  isFutureRoute?: boolean;
}) => {
  const salesItemSummary = getSalesItemsSummary(routeSettlement);

  return (
    <div className='flex flex-col gap-2'>
      <p className='my-2 text-5 font-semibold'>🧰 Tasks {isFutureRoute ? '' : 'performed'}:</p>
      {Object.entries(salesItemSummary).map(([key, { name, count, carSizes }]) => (
        <div
          key={key}
          className='mt-1 p-2 [&:not(:last-child)]:border-b-[1.5px] [&:not(:last-child)]:border-primary-purple'
        >
          <p>{name}</p>
          <p>Car size: {carSizes.map((carSize) => carSize.name).join(', ')}</p>
          <p>Quantity on this route: {count}</p>
        </div>
      ))}
    </div>
  );
};

export const Payment = ({
  routeSettlement,
  isFutureRoute
}: {
  routeSettlement: RouteSettlementPayment;
  isFutureRoute?: boolean;
}) => {
  const hasUnableToCompletePayment = parseInt(routeSettlement.unableToCompletePayment ?? '0') > 0;
  const waitAndDriveTimePayment =
    parseInt(routeSettlement?.waitingTimePayment ?? '0') + parseInt(routeSettlement?.drivingTimePayment ?? '0');

  const totalPayment = parseInt(routeSettlement.settledPayment ?? '0') + parseInt(routeSettlement.extraPayment ?? '0');

  if (isFutureRoute) {
    return <FutureRoutePayment routeSettlement={routeSettlement} />;
  }
  return (
    <div className='flex flex-col gap-2'>
      <p>
        Wait & drive time payment:{' '}
        <span className='font-bold'>
          {formatCurrencyAmount(
            parseInt(routeSettlement?.waitingTimePayment ?? '0') + parseInt(routeSettlement?.drivingTimePayment ?? '0')
          )}
        </span>
      </p>
      {hasUnableToCompletePayment && (
        <p>
          You got{' '}
          <span className='font-bold'>{formatCurrencyAmount(Number(routeSettlement.unableToCompletePayment))}</span>{' '}
          from jobs that you were not able to complete.
        </p>
      )}
      <p>
        The payout from this route is in total <span className='font-bold'> {formatCurrencyAmount(totalPayment)}</span>.
        This is based on a payment from performed jobs of
        <span className='font-bold'>
          {' '}
          {formatCurrencyAmount(Number(routeSettlement.sumSalesItemsLevel1Payment))}
        </span>{' '}
        and payment from the time spent in your car of{' '}
        <span className='font-bold'>{formatCurrencyAmount(waitAndDriveTimePayment)}</span>.
        {parseInt(routeSettlement.extraPayment) > 0 && (
          <>
            You've also received an extra payment of{' '}
            <span className='font-bold'>{formatCurrencyAmount(Number(routeSettlement.extraPayment))}</span>
            {routeSettlement.extraPaymentDescription ? ` (${routeSettlement.extraPaymentDescription}).` : '.'}
          </>
        )}
      </p>
      <p>
        The additional payment from this route is{' '}
        <span className='font-bold'> {formatCurrencyAmount(Number(routeSettlement.kilometerPayment))}</span> for the
        kilometers traveled, and{' '}
        <span className='font-bold'>{formatCurrencyAmount(Number(routeSettlement.tollCost))}</span> in road toll.
      </p>

      {routeSettlement.performancePayment === routeSettlement.computedMinimumPayment && (
        <p>
          Minimum payment {isFutureRoute ? 'is estimated to' : 'was'}:
          {formatCurrencyAmount(Number(routeSettlement.computedMinimumPayment))}
        </p>
      )}
    </div>
  );
};
