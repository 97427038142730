import { Menu } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';

import { usePopover } from '../../../../hooks/usePopover';
import { NoddiDataTableTimePickerFilter, NoddiDataTableTimePickerStaticFilter } from '../../../../types';
import FilterButton from './FilterButton';

type FilterProps = 'label' | 'onChange' | 'value' | 'name';

type DateFilterProps =
  | Pick<NoddiDataTableTimePickerFilter, FilterProps>
  | Pick<NoddiDataTableTimePickerStaticFilter, FilterProps>;

const TableDatePickerFilter = ({ label, onChange, value }: DateFilterProps) => {
  const { anchorRef, handleClose, handleOpen, open } = usePopover<HTMLButtonElement>();

  return (
    <>
      <FilterButton anchorRef={anchorRef} handleClose={handleClose} handleOpen={handleOpen} open={open}>
        {label}
      </FilterButton>
      <Menu anchorEl={anchorRef.current} open={open} onClose={handleClose}>
        <DateCalendar
          onChange={(value) => {
            if (value === null) {
              // if the value is null, we want to call the onChange function with null
              // which can be safely casted to the correct callback function
              const callback = onChange as (date: Date | null) => void;
              return callback(value);
            }

            return onChange(value);
          }}
          value={value ?? null}
        />
      </Menu>
    </>
  );
};

export { TableDatePickerFilter };
