import { SVGProps } from 'react';

interface ArrowRightProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const ArrowRight = ({ color, ...props }: ArrowRightProps) => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M7.467 16h17.066m0 0-6.4-6.4m6.4 6.4-6.4 6.4'
    />
  </svg>
);

export { ArrowRight };
