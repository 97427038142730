import { OutlinedInput, OutlinedInputProps } from '@mui/material';

const NoddiTextAreaInput = (props: OutlinedInputProps) => {
  const { label, name } = props;

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <OutlinedInput fullWidth multiline type='textarea' {...props} />
    </div>
  );
};

export { NoddiTextAreaInput };
