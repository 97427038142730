import { NoddiButton } from 'noddi-ui';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

interface HomeContentWrapperProps {
  title?: string;
  subtitle?: string;
  hideBackButton?: boolean;
}

const HomeContentWrapper = ({
  children,
  title,
  subtitle,
  hideBackButton
}: PropsWithChildren<HomeContentWrapperProps>) => {
  const hasTitles = !!title || !!subtitle;
  const navigate = useNavigate();

  return (
    <div className='mx-auto mt-8 flex max-w-screen-mdx flex-col px-6 pb-6 sm:px-10 md:mt-14'>
      {!hideBackButton && (
        <NoddiButton
          startIcon='ArrowLeft'
          onClick={() => {
            navigate(-1);
          }}
          variant='ghost'
          className='-ml-4'
        >
          Back
        </NoddiButton>
      )}
      {hasTitles && (
        <div className='mb-4 flex flex-col gap-2'>
          {title && <h1 className='text-7 sm:text-13'>{title}</h1>}
          {subtitle && <p>{subtitle}</p>}
        </div>
      )}
      {children}
    </div>
  );
};

export default HomeContentWrapper;
