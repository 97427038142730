import { UserData } from 'noddi-async/src/types';
import { UserRoleType } from 'noddi-provider';

function hasValidPermission(requiredLoginLevels: UserRoleType[], user: UserData) {
  let isAllowed = false;

  for (const requiredLoginLevel of requiredLoginLevels) {
    if (user[requiredLoginLevel]) {
      isAllowed = true;
      break;
    }
  }
  return isAllowed;
}

export { hasValidPermission };
