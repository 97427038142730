import { CustomerCarGeneration } from 'noddi-async/src/types';

export function getUniquePsiValues(tirePressures: CustomerCarGeneration['dimensions']['frontTirePsiPressures']) {
  if (tirePressures.length === 0) {
    return { psiValues: null, hasMultipleValues: false };
  }
  // Extract the psi values from all pressures
  const psiValues = tirePressures.map((pressure) => pressure.psi);

  // Find the minimum and maximum psi values
  const minPsi = Math.min(...psiValues);
  const maxPsi = Math.max(...psiValues);

  // Return a single value if min and max are the same, otherwise return the range
  const psiKeyValues = minPsi === maxPsi ? `${minPsi}` : `${minPsi}-${maxPsi}`;

  return { psiValues: psiKeyValues, hasMultipleValues: psiValues.length > 1 };
}
