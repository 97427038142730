import { commonTexts, getAddressYupObject } from 'noddi-ui';
import * as Yup from 'yup';

export const getValidationSchema = () =>
  Yup.object({
    dates: Yup.array()
      .of(Yup.string().required('Required'))
      .min(1, 'At least one date is required')
      .required('Required'),
    licenseAreaId: Yup.number().required('Required'),
    startTime: Yup.string().required('Required'),
    endTime: Yup.string().required('Required'),
    maxDuration: Yup.number()
      .min(4, 'Max duration must be at least 4 hours')
      .max(12, 'Max duration cannot exceed 12 hours')
      .required('Max duration is required'),
    startAddress: Yup.lazy((value) =>
      typeof value === 'object'
        ? getAddressYupObject(commonTexts.addressValidationTranslations)
        : Yup.number().required('Required')
    ),
    endAddress: Yup.lazy((value) =>
      typeof value === 'object'
        ? getAddressYupObject(commonTexts.addressValidationTranslations)
        : Yup.number().required('Required')
    )
  });
