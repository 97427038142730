import { Stack } from '@mui/system';
import { DateFormats, format } from 'noddi-util';

import { NoddiChip } from '../../../../atoms/NoddiChip';
import { NoddiDataTableFilterType, dataTableFilterTypes } from '../../../../types';
import { getActiveFilters } from '../NoddiDataTable/utils';

interface Props {
  filters: NoddiDataTableFilterType[];
}

const NoddiActiveFilterChips = ({ filters }: Props) => {
  const activeFilters = getActiveFilters(filters);

  return (
    <Stack direction='row' flexWrap='wrap' gap={0.5} paddingBottom={1.5}>
      {activeFilters.map((filter, index) => {
        switch (filter.type) {
          case dataTableFilterTypes.timePicker: {
            if (!filter.value) {
              return null;
            }
            return (
              <NoddiChip
                key={index}
                label={`${filter.label}: ${format(filter.value, DateFormats.DOTTED_DATE)}`}
                onDelete={filter.clearFilter}
              />
            );
          }
          case dataTableFilterTypes.timePickerStatic: {
            return (
              <NoddiChip key={index} label={`${filter.label}: ${format(filter.value, DateFormats.DOTTED_DATE)}`} />
            );
          }
          case dataTableFilterTypes.singleSelect: {
            const label = filter.options.find((option) => option.value === filter.value)?.label;
            return <NoddiChip key={index} label={`${filter.label}: ${label}`} onDelete={filter.clearFilter} />;
          }
          case dataTableFilterTypes.multiSelect: {
            const options = filter.options.filter((option) => {
              const value = filter.value.find((value) => value === option.value);
              if (value) {
                return value;
              }
              if (value === 0) {
                return true;
              }
              return undefined;
            });

            return (
              <>
                {options.map((option, index) => {
                  const filteredValues = filter.value.filter((value) => value !== option.value);

                  // sets the values to be equal to the filtered values
                  return (
                    <NoddiChip
                      key={index}
                      label={`${filter.label}: ${option.label}`}
                      // @ts-expect-error
                      onDelete={() => filter.clearFilter(filteredValues)}
                    />
                  );
                })}
              </>
            );
          }
          default:
            return null;
        }
      })}
    </Stack>
  );
};

export default NoddiActiveFilterChips;
