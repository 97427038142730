import type { ThemeOptions } from '@mui/material/styles/createTheme';

import type { Direction } from '..';
import { createComponents } from './createComponents';
import { createTypography } from './createTypography';

interface Config {
  direction?: Direction;
}

// Here we do not modify the "palette" and "shadows" because "light" and "dark" mode
// may have different values.

// Typescript module augmentation
// adds the "xxl" breakpoint to the BreakpointOverrides interface
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    dashboard: true;
  }
}

export const createOptions = (config: Config): ThemeOptions => {
  const { direction = 'ltr' } = config;

  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
        xxl: 1920,
        dashboard: 2560
      }
    },
    components: createComponents(),
    direction,
    shape: {
      borderRadius: 8
    },
    typography: createTypography()
  };
};
