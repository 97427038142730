import { IconSvgProps } from './svgtype';

const Wheel = ({ color, ...props }: IconSvgProps) => (
  <svg width={30} height={30} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='15' cy='15' r='10' stroke={color} strokeWidth='1.5' />
    <circle cx='15' cy='15' r='6' stroke={color} strokeWidth='1.5' />
    <circle cx='15' cy='15' r='2' stroke={color} strokeWidth='1.5' />
    <path d='M9 15L13 15' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path d='M17 15L21 15' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path d='M12 20.1963L14 16.7322' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path d='M16 13.2681L18 9.80396' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path d='M18 20.1963L16 16.7322' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path d='M14 13.2681L12 9.80396' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
  </svg>
);

export { Wheel };
