export function stringToUpperCase(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function startsWithIgnoreCase(value: string, search: string) {
  return value.toLowerCase().startsWith(search.toLowerCase());
}

export function thousandSeparator(value: string, separator = ' ') {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export const trimAllWhiteCharacters = (mobileNumber: string) => mobileNumber.replace(/\s+/g, '');
