import { Rating } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { NoddiAsyncError } from 'noddi-async/src/types';
import { ErrorPage, LoadingScreen, PaginatedWrapper, usePagination } from 'noddi-ui';
import { DateFormats, format, subDays } from 'noddi-util';
import { useEffect, useState } from 'react';

import useAxiosPrivate from '../../adapters/xhr/axiosPrivate';
import { ApiEndpointIdentifier } from '../../constant/apiRoute';
import { getEndpointUrl } from '../../helper';
import HomeContentWrapper from '../../layouts/HomeContentWrapper';
import { PaginatedResponse } from '../../types/server';

const dayFilterOptios = ['Last 14 days', 'Last 30 days', 'All time'];

interface OverallFeedback {
  avg_customer_rating_car_result: number;
  avg_customer_rating_politeness: number;
  service_feedbacks: PaginatedResponse<Feedback[]>;
}

interface Feedback {
  date: string;
  customer_rating_car_result: number;
  customer_rating_politeness?: number;
  customer_comment: string;
}

const MyFeedback = () => {
  const axiosPrivate = useAxiosPrivate();
  const { page, setPage, pageSize, setPageSize } = usePagination();
  const [dayFilterOption, setDayFilterOption] = useState(dayFilterOptios[0]);

  const endpoint =
    dayFilterOption === 'All time'
      ? ApiEndpointIdentifier.SERVICE_WORKER_FEEDBACKS
      : ApiEndpointIdentifier.SERVICE_WORKER_FEEDBACKS_WITH_DATES;
  const {
    data: feedbacks,
    isLoading,
    isPending,
    isFetching,
    isSuccess,
    refetch
  } = useQuery<OverallFeedback, NoddiAsyncError>({
    queryKey: [ApiEndpointIdentifier.SERVICE_WORKER_FEEDBACKS],
    queryFn: async () => {
      const defaultParams = {
        pageSize,
        page
      };
      const params =
        dayFilterOption === 'All time'
          ? defaultParams
          : {
              ...defaultParams,
              fromDate: format(
                subDays(new Date(), dayFilterOption === 'Last 14 days' ? 14 : 30),
                DateFormats.DASHED_DATE_ISO_8601
              ),
              toDate: format(new Date(), DateFormats.DASHED_DATE_ISO_8601)
            };
      return axiosPrivate.get(getEndpointUrl(endpoint, params)).then((res) => res.data);
    }
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, dayFilterOption]);

  if (isPending) {
    return <LoadingScreen />;
  }
  if (!isSuccess) {
    return <ErrorPage />;
  }

  const isLoadingFeedbacks = isLoading || isFetching;

  if (isLoadingFeedbacks) {
    return <LoadingScreen />;
  }
  const overAllPolitenessScore = Math.round(feedbacks.avg_customer_rating_politeness * 100) / 100;
  const overAllCarResultScore = Math.round(feedbacks.avg_customer_rating_car_result * 100) / 100;
  return (
    <HomeContentWrapper title='Feedback'>
      <div className='flex w-full flex-col gap-3'>
        <select
          value={dayFilterOption}
          onChange={(e) => setDayFilterOption(e.target.value.toString())}
          className='rounded-lg p-2'
        >
          {dayFilterOptios.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div className='flex w-full gap-6'>
          <div className='flex w-full flex-col items-center justify-center rounded-xl bg-primary-white p-4'>
            <h6 className='text-3.5'>Politeness</h6>
            <h4 className='text-6'>{overAllPolitenessScore}</h4>
            <h6 className='text-3.5'>out of 5</h6>
          </div>
          <div className='flex w-full flex-col items-center justify-center rounded-xl bg-primary-white p-4'>
            <h6 className='text-3.5'>Car result</h6>
            <h4 className='text-6'>{overAllCarResultScore}</h4>
            <h6 className='text-3.5'>out of 5</h6>
          </div>
        </div>
        <div className='-mx-6'>
          <PaginatedWrapper
            translations={{ rowsPerPage: 'Rows per page', of: 'of' }}
            className='!pr-4'
            page={page}
            setPage={setPage}
            setRowsPerPage={setPageSize}
            rowsPerPage={pageSize}
            hidden={feedbacks.service_feedbacks.results.length === 0}
            count={feedbacks.service_feedbacks.count}
          >
            <div className='flex flex-col gap-3'>
              {feedbacks.service_feedbacks.results.map((feedback, index) => (
                <div key={index} className='mx-6 rounded-xl bg-primary-white px-4 py-3'>
                  <div className='flex justify-between gap-2'>
                    <div className='flex flex-col gap-2'>
                      <p className='font-semibold'>{format(new Date(feedback.date), DateFormats.FULL_MONTH_YEAR)}</p>

                      {feedback.customer_comment && <p className='py-1 text-3.5'>{feedback.customer_comment}</p>}
                    </div>

                    <div>
                      <div className='flex flex-col'>
                        <p className='text-start text-3.5'>Politeness</p>
                        <Rating
                          size='small'
                          name='rating'
                          precision={0.5}
                          max={5}
                          value={feedback.customer_rating_politeness}
                          readOnly
                        />
                      </div>
                      <div className='flex flex-col'>
                        <p className='text-3.5'>Car result</p>
                        <Rating
                          size='small'
                          name='rating'
                          precision={0.5}
                          max={5}
                          value={feedback.customer_rating_car_result}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </PaginatedWrapper>
        </div>
      </div>
    </HomeContentWrapper>
  );
};

export default MyFeedback;
