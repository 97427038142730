import { useNavigate, useSearchParams } from 'react-router-dom';

const useNavigateOnLoginSuccess = () => {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');
  const navigate = useNavigate();

  function navigateOnLoginSuccess({ loginSuccessPath }: { loginSuccessPath: string }) {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate(loginSuccessPath);
    }
  }

  return { navigateOnLoginSuccess };
};

export { useNavigateOnLoginSuccess };
