import { IconSvgProps } from './svgtype';

const Heart = ({ color = '#1C274C', ...props }: IconSvgProps) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.7592 23.3719L13.2235 22.7829L12.7592 23.3719ZM16 9.0676L15.4596 9.5877C15.601 9.73459 15.7961 9.8176 16 9.8176C16.2039 9.8176 16.399 9.73459 16.5404 9.5877L16 9.0676ZM19.2408 23.3719L19.7051 23.9609L19.2408 23.3719ZM13.2235 22.7829C11.6098 21.5107 9.83641 20.2621 8.43012 18.6785C7.04954 17.124 6.08334 15.306 6.08334 12.9465H4.58334C4.58334 15.7741 5.76082 17.9318 7.30855 19.6746C8.83057 21.3884 10.7701 22.7589 12.2949 23.9609L13.2235 22.7829ZM6.08334 12.9465C6.08334 10.6335 7.39037 8.69129 9.17853 7.87392C10.9194 7.07816 13.2517 7.2937 15.4596 9.5877L16.5404 8.5475C13.9485 5.85465 10.9474 5.41607 8.55494 6.50969C6.20972 7.58169 4.58334 10.0723 4.58334 12.9465H6.08334ZM12.2949 23.9609C12.8417 24.3919 13.4256 24.849 14.0165 25.1943C14.6072 25.5395 15.2763 25.8169 16 25.8169V24.3169C15.6571 24.3169 15.2595 24.1833 14.7733 23.8992C14.2874 23.6153 13.7841 23.2248 13.2235 22.7829L12.2949 23.9609ZM19.7051 23.9609C21.2299 22.7589 23.1694 21.3884 24.6915 19.6746C26.2392 17.9318 27.4167 15.7741 27.4167 12.9465H25.9167C25.9167 15.306 24.9505 17.124 23.5699 18.6785C22.1636 20.2621 20.3902 21.5107 18.7765 22.7829L19.7051 23.9609ZM27.4167 12.9465C27.4167 10.0723 25.7903 7.58169 23.4451 6.50969C21.0526 5.41607 18.0515 5.85465 15.4596 8.5475L16.5404 9.5877C18.7483 7.2937 21.0806 7.07816 22.8215 7.87392C24.6096 8.69129 25.9167 10.6335 25.9167 12.9465H27.4167ZM18.7765 22.7829C18.2159 23.2248 17.7127 23.6153 17.2267 23.8992C16.7405 24.1833 16.3429 24.3169 16 24.3169V25.8169C16.7237 25.8169 17.3928 25.5395 17.9835 25.1943C18.5744 24.849 19.1583 24.3919 19.7051 23.9609L18.7765 22.7829Z'
      fill={color}
    />
  </svg>
);

export { Heart };
