export enum FetchMethods {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete',
  patch = 'patch'
}

export const apiVersion = 'v1/';

export type ResponseType = 'json' | 'blob' | 'text' | 'arraybuffer';
