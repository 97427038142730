import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { NoddiButton } from '../../Buttons';

/**
 * The reason for this existence instead of using ErrorPage is that we can't
 * render any translations before the initProvider is ran, and this is a fallback
 * for the topmost sentry error boundary.
 */
const NbFallback = () => {
  return (
    <Stack
      direction='column'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh',
        textAlign: 'center'
      }}
    >
      <Typography variant='h3'>Vi beklager, det har oppstått en feil.</Typography>
      <Typography variant='subtitle1'>Vi jobber med å løse problemet, prøv igjen senere</Typography>
      <NoddiButton variant='primary' onClick={() => window.location.reload()} style={{ marginTop: '20px' }}>
        <Typography>Prøv igjen</Typography>
      </NoddiButton>
    </Stack>
  );
};

export { NbFallback };
