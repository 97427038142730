import { IconSvgProps } from './svgtype';

const MapArrows = ({ color, ...props }: IconSvgProps) => (
  <svg width={30} height={30} fill='none' viewBox='0 0 30 30' {...props}>
    <path
      stroke={color}
      strokeWidth={1.5}
      d='M5 8.257C5 6.458 6.567 5 8.5 5S12 6.458 12 8.257c0 1.785-1.117 3.868-2.86 4.613a1.638 1.638 0 0 1-1.28 0C6.117 12.125 5 10.042 5 8.257ZM18 20.257C18 18.458 19.567 17 21.5 17s3.5 1.458 3.5 3.257c0 1.785-1.117 3.868-2.86 4.613a1.638 1.638 0 0 1-1.28 0c-1.743-.745-2.86-2.828-2.86-4.613Z'
    />
    <path
      fill={color}
      d='M22.5 20.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9.5 8.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM15 7.25a.75.75 0 0 0 0 1.5v-1.5ZM15 22l.53.53a.75.75 0 0 0 0-1.06L15 22Zm5.206-10.313.402.633-.402-.633ZM9.794 18.313l.403.632-.403-.632Zm4.236 1.657a.75.75 0 1 0-1.06 1.06l1.06-1.06Zm-1.06 3a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm6.162-15.72H15v1.5h4.132v-1.5ZM15 21.25h-4.132v1.5H15v-1.5Zm4.803-10.195L9.392 17.68l.805 1.265 10.411-6.625-.805-1.265ZM15.53 21.47l-1.5-1.5-1.06 1.06 1.5 1.5 1.06-1.06Zm-1.06 0-1.5 1.5 1.06 1.06 1.5-1.5-1.06-1.06Zm-3.602-.22c-1.25 0-1.726-1.633-.671-2.305l-.805-1.265c-2.321 1.477-1.275 5.07 1.476 5.07v-1.5Zm8.264-12.5c1.25 0 1.726 1.633.671 2.305l.805 1.265c2.321-1.477 1.275-5.07-1.476-5.07v1.5Z'
    />
  </svg>
);
export { MapArrows };
