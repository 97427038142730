import { MenuItem } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';
import { ReactNode } from 'react';

type Option<Value> = {
  label: ReactNode;
  value: Value;
};

export interface NoddiSelectProps<Value> extends SelectProps<Value> {
  label: ReactNode;
  options: Option<Value>[];
  classname?: string;
}

const NoddiSelect = <Value,>({ name, label, options, className, ...rest }: NoddiSelectProps<Value>) => {
  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <Select {...rest}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value as string | number}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export { NoddiSelect };
