import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { commonTexts } from '../../../commonTexts';
import { NoddiButton } from '../Buttons';

interface Error404PageProps {
  translations?: {
    pageNotFoundHeading: string;
    pageNotFoundMessage: string;
    goBack: string;
  };
}

const Error404Page = ({ translations }: Error404PageProps) => {
  const navigate = useNavigate();

  const texts = translations ?? {
    pageNotFoundHeading: commonTexts.pageNotFoundHeading,
    pageNotFoundMessage: commonTexts.pageNotFoundMessage,
    goBack: commonTexts.goBack
  };

  const goBack = () => navigate(-1);

  return (
    <Stack className='-m-40 min-h-screen' justifyContent='center' alignItems='center' textAlign='center'>
      <div className='flex flex-col items-center gap-6'>
        <p className='font-bold text-8 text-primary-darkPurple'>{texts.pageNotFoundHeading}</p>

        <p className='w-2/3 text-5 text-primary-darkPurple'>{texts.pageNotFoundMessage}</p>
        <NoddiButton onClick={goBack}>
          &larr;
          {texts.goBack}
        </NoddiButton>
      </div>
    </Stack>
  );
};

export { Error404Page };
