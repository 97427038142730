import { commonTexts } from '../../../../commonTexts';
import { NoddiDataTableFilterType, dataTableFilterTypes } from '../../../../types';
import { NoddiButton } from '../../Buttons';
import { getActiveFilters } from '../NoddiDataTable/utils';
import { TableDatePickerFilter } from './TableDatePickerFilter';
import { TableMultiSelectFilter } from './TableMultiSelectFilter';
import { TableSelectFilter } from './TableSelectFilter';

interface Props {
  filters: NoddiDataTableFilterType[];
  clearFilters?: () => void;
  translations?: {
    clearFilters: string;
  };
}

const NoddiTableFilterList = ({ filters, clearFilters, translations }: Props) => {
  const hasActiveFilters = getActiveFilters(filters).length > 0;

  const texts = translations ?? commonTexts.table;
  return (
    <>
      {filters.map((filter, index) => {
        switch (filter.type) {
          case dataTableFilterTypes.timePickerStatic:
          case dataTableFilterTypes.timePicker:
            return <TableDatePickerFilter {...filter} key={index} />;
          case dataTableFilterTypes.singleSelect:
            // @ts-expect-error
            return <TableSelectFilter {...filter} key={index} />;
          case dataTableFilterTypes.multiSelect:
            return (
              <TableMultiSelectFilter
                options={filter.options}
                value={filter.value}
                name={filter.name}
                //@ts-expect-error
                onChange={filter.onChange}
                label={filter.label}
                key={index}
                disabled={filter.disabled}
              />
            );
          default:
            return null;
        }
      })}
      {hasActiveFilters && clearFilters && (
        <NoddiButton variant='link' onClick={clearFilters}>
          {texts.clearFilters}
        </NoddiButton>
      )}
    </>
  );
};

export { NoddiTableFilterList };
