/* eslint-disable @typescript-eslint/no-explicit-any */

type StorageWrapper = {
  getItem: <T>(key: string) => T | null;
  setItem: <T>(key: string, value: T) => void;
  removeItem: (key: string) => void;
  clear: () => void;
};

const create = (storage: Storage): StorageWrapper => ({
  getItem: <T>(key: string) => {
    try {
      const item = storage.getItem(key);
      return item ? deserialize(JSON.parse(item) as T) : null;
    } catch (_error) {
      return null;
    }
  },

  setItem: <T>(key: string, value: T) => {
    try {
      storage.setItem(key, JSON.stringify(serialize(value)));
    } catch (_error) {
      console.error('Could not save item');
    }
  },

  removeItem: (key: string) => {
    try {
      storage.removeItem(key);
    } catch (_error) {
      console.error('Could not remove item');
    }
  },

  clear: () => {
    try {
      storage.clear();
    } catch (_error) {
      console.error('Could not clear storage');
    }
  }
});

function serialize(value: any): any {
  if (value instanceof Map) {
    return {
      __isMap: true,
      data: [...value.entries()].map(([k, v]) => [k, serialize(v)])
    };
  } else if (Array.isArray(value)) {
    return value.map(serialize);
  } else if (typeof value === 'object' && value !== null) {
    const obj: any = {};
    for (const key in value) {
      obj[key] = serialize(value[key]);
    }
    return obj;
  }
  return value;
}
// Custom deserialization function
function deserialize(value: any): any {
  if (value && value.__isMap) {
    return new Map(value.data.map(([k, v]: [any, any]) => [k, deserialize(v)]));
  } else if (Array.isArray(value)) {
    return value.map(deserialize);
  } else if (typeof value === 'object' && value !== null) {
    const obj: any = {};
    for (const key in value) {
      obj[key] = deserialize(value[key]);
    }
    return obj;
  }
  return value;
}

export const storage = {
  local: create(window.localStorage),
  session: create(window.sessionStorage)
};
