type InfoPageProps = {
  title: string;
  message: string;
};

const InfoPage = ({ message, title }: InfoPageProps) => {
  return (
    <div className='flex min-h-[60vh] flex-col justify-center text-center'>
      <h2 className='font-bold'>{title}</h2>
      <p>{message}</p>
    </div>
  );
};

export { InfoPage };
