import { IconSvgProps } from './svgtype';

const BadgePlus = ({ color = '#1C274C', ...props }: IconSvgProps) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.13228 11.4934C4.93767 10.6167 4.96755 9.70514 5.21915 8.84314C5.47076 7.98113 5.93594 7.19661 6.57157 6.56231C7.2072 5.92802 7.99269 5.46448 8.85522 5.21468C9.71775 4.96488 10.6294 4.9369 11.5056 5.13335C11.9879 4.37909 12.6523 3.75836 13.4375 3.3284C14.2228 2.89843 15.1037 2.67307 15.9989 2.67307C16.8942 2.67307 17.7751 2.89843 18.5603 3.3284C19.3456 3.75836 20.01 4.37909 20.4923 5.13335C21.3698 4.93605 22.283 4.9639 23.1469 5.2143C24.0108 5.46471 24.7974 5.92954 25.4334 6.56556C26.0694 7.20157 26.5343 7.98812 26.7847 8.85202C27.0351 9.71593 27.0629 10.6291 26.8656 11.5067C27.6199 11.989 28.2406 12.6534 28.6706 13.4386C29.1005 14.2239 29.3259 15.1047 29.3259 16C29.3259 16.8953 29.1005 17.7762 28.6706 18.5614C28.2406 19.3467 27.6199 20.0111 26.8656 20.4934C27.0621 21.3696 27.0341 22.2812 26.7843 23.1437C26.5345 24.0063 26.0709 24.7918 25.4367 25.4274C24.8024 26.063 24.0178 26.5282 23.1558 26.7798C22.2938 27.0314 21.3822 27.0613 20.5056 26.8667C20.024 27.6238 19.359 28.2472 18.5724 28.6791C17.7858 29.111 16.903 29.3374 16.0056 29.3374C15.1082 29.3374 14.2254 29.111 13.4388 28.6791C12.6522 28.2472 11.9873 27.6238 11.5056 26.8667C10.6294 27.0631 9.71775 27.0352 8.85522 26.7854C7.99269 26.5356 7.2072 26.072 6.57157 25.4377C5.93594 24.8034 5.47076 24.0189 5.21915 23.1569C4.96755 22.2949 4.93767 21.3833 5.13228 20.5067C4.37222 20.0257 3.74616 19.3602 3.31234 18.5723C2.87852 17.7844 2.65103 16.8995 2.65103 16C2.65103 15.1005 2.87852 14.2157 3.31234 13.4277C3.74616 12.6398 4.37222 11.9744 5.13228 11.4934Z'
      stroke={color}
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path d='M16 10.6667V21.3334' stroke={color} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M10.6667 16H21.3333' stroke={color} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
  </svg>
);

export { BadgePlus };
