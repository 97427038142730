import { Skeleton } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { NoddiChip } from 'noddi-ui';
import { secondsToMinutes } from 'noddi-util';

import { isRouteWithinTimeThreshold } from '../../utils/routeItem';

const TimeStatusChip = ({ routeId }: { routeId: number }) => {
  const {
    data: delayData,
    isPending: isDelayPending,
    isFetching: isDelayFetching,
    error: delayError
  } = noddiAsync.useGet({
    type: URLKeys.getDelayForRoute,
    input: { routeId }
  });

  // we listen to isRouteFetching because we want to show loading screen while invalidating query which is not reflected in isPending
  if (isDelayPending || isDelayFetching) {
    return <Skeleton variant='rectangular' width={120} height={30} />;
  }
  if (delayError) {
    return null;
  }

  const delayInSeconds = delayData?.delayInSeconds;

  const isDelayed = delayInSeconds > 0;

  const delayInMinutes = secondsToMinutes(delayInSeconds);
  const isDelayWithinThreshold = isRouteWithinTimeThreshold(delayInSeconds);

  const delayInMinutesAbs = Math.abs(delayInMinutes);

  const textString = isDelayed ? `${delayInMinutesAbs} min behind` : `${delayInMinutesAbs} min early `;
  const isRightOnTime = delayInMinutes === 0;

  // we need to return empty div here for justify between to take effect in parent component
  return isRightOnTime ? (
    <div />
  ) : (
    <NoddiChip label={textString} variant={isDelayWithinThreshold ? 'success' : 'warning'} />
  );
};

export default TimeStatusChip;
