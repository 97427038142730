import { PropsWithChildren } from 'react';

import { NoddiIcon } from '../atoms';

interface NoddiSelectableBoxProps {
  isSelected: boolean;
  setIsSelected: (isSelected: boolean) => void;
}

const NoddiSelectableBox = ({ children, isSelected, setIsSelected }: PropsWithChildren<NoddiSelectableBoxProps>) => {
  return (
    <button
      onClick={() => setIsSelected(!isSelected)}
      className='flex w-full items-center gap-4 rounded-lg border bg-systemColors-purpleBg px-5 py-3.5 active:opacity-50'
    >
      <div>
        {isSelected ? <NoddiIcon name='CheckedRectangle' size='small' /> : <NoddiIcon name='Rectangle' size='small' />}
      </div>
      {children}
    </button>
  );
};

export { NoddiSelectableBox };
