import { Currency } from 'noddi-async/src/types';

const CURRENCY_FORMAT_FALLBACK_VALUE = '-';
const MAXIMUM_FRACTION_DIGITS = 2;
const FORMATTER_STYLE = 'currency';

function getCurrencyLocales(currency: Currency) {
  switch (currency) {
    case 'NOK':
      return 'no-NO';
    case 'SEK':
      return 'sv-SE';
  }
}

export const formatCurrencyAmount = (
  amount?: number,
  fractionDigits: number = MAXIMUM_FRACTION_DIGITS,
  currency: Currency = 'NOK'
): string => {
  if (amount === undefined || amount === null) {
    return CURRENCY_FORMAT_FALLBACK_VALUE;
  }

  // Get the actual decimal places the number has
  const decimalPlaces = amount.toString().split('.')[1]?.length || 0;

  // Adjust the number of fraction digits to match the actual decimals, or limit by the passed fractionDigits
  const actualFractionDigits = Math.min(decimalPlaces, fractionDigits);

  const currencyLocales = getCurrencyLocales(currency);

  const currencyFormatter = new Intl.NumberFormat(currencyLocales, {
    style: FORMATTER_STYLE,
    currency,
    minimumFractionDigits: actualFractionDigits,
    maximumFractionDigits: actualFractionDigits
  });

  return currencyFormatter.format(amount);
};
