import styled from 'styled-components';

const UnstyledButton = styled.button`
  all: unset;
  &:hover,
  &:focus,
  &:visited,
  &:link,
  &:active {
    all: unset;
  }
  &:hover {
    cursor: pointer;
  }
`;

export { UnstyledButton };
