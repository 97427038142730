import { CircularProgress, TextField } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { Field, FieldProps } from 'formik';
import { useState } from 'react';

import { NoddiAutocomplete } from '../../Inputs';
import { FormFieldErrorMessage } from '../FormFieldErrorMessage';
import { AutocompleteOption } from './interface';

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  options?: AutocompleteOption[];
  defaultOption?: AutocompleteOption | null;
  onInputChange?: (value: string) => void;
  onChange?: (value: AutocompleteOption | null) => void;
  onClear?: () => void;
  isLoading?: boolean;
  placeholder?: string;
}
interface AutocompleteProps extends Props, FieldProps {}

function AutocompleteComponent({
  disabled,
  options = [],
  defaultOption,
  onInputChange,
  onChange,
  onClear,
  isLoading,
  placeholder,
  form,
  field
}: AutocompleteProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [value, setValue] = useState<any>(defaultOption ?? null);

  let debouncedOnInput: (input: string) => void | undefined;
  if (onInputChange) {
    debouncedOnInput = debounce(onInputChange, 500);
  }

  return (
    <NoddiAutocomplete
      disabled={disabled}
      value={value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(_event, newValue) => {
        setValue(newValue);
        if (onChange) {
          return onChange(newValue);
        }

        form.setFieldValue(field.name, newValue).catch((error) => console.error(error));
      }}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason === 'clear' && onClear) {
          onClear();
        }
        if (debouncedOnInput) {
          debouncedOnInput(newInputValue);
        }
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress className='text-primary-darkPurple' size={20} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          placeholder={placeholder}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      filterOptions={(options, params) => {
        return options.filter((option) => {
          if (params.inputValue === '') {
            return true;
          }
          const optionLabelList: string[] = option.label.toLowerCase().split(' ');
          const inputValueList: string[] = params.inputValue.toLowerCase().split(' ');

          return optionLabelList.some((optionLabel) => {
            return inputValueList.some((inputValue) => optionLabel.startsWith(inputValue));
          });
        });
      }}
    />
  );
}

export const NoddiFormAutocomplete = ({ name, label, ...rest }: Props) => {
  return (
    <div>
      {label && <label htmlFor={name}>{label}</label>}
      <Field name={name} component={AutocompleteComponent} {...rest} />
      <FormFieldErrorMessage name={name} component='div' />
    </div>
  );
};
