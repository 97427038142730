import { MenuItem, Select } from '@mui/material';
import { AddressProps } from 'noddi-async/src/types';
import { CSSProperties, useState } from 'react';

import { commonTexts } from '../../../commonTexts';
import { getLongAddressName } from '../../../helpers';
import { useNoddiToast } from '../../../providers/toast';
import { GoogleMapsPlaceSearch } from './GoogleAddress';
import { GoogleMapsSearchProps } from './GoogleAddress/GoogleMapsPlaceSearch';
import { extractAddressFromLatLng } from './addressUtils';

export type AddressPickerProps = {
  addresses: AddressProps[];
  onSelectAddress: (address: AddressProps | null) => void;
  selectedAddress?: AddressProps | null;
  initWithCustomSearch?: boolean;
  style?: CSSProperties;
  hideBottomOptions?: boolean;
  translations?: {
    couldNotFindAddress: string;
    addressNotFound: string;
    tryAgainWithStreetNumber: string;
    chooseFromAnotherAddress: string;
    chooseFromYourAddresses: string;
  };
} & Pick<GoogleMapsSearchProps, 'placeIdFromUrl'>;

const AddressPicker = ({
  addresses,
  onSelectAddress,
  selectedAddress,
  initWithCustomSearch,
  placeIdFromUrl,
  style,
  hideBottomOptions = false,
  translations
}: AddressPickerProps) => {
  const [useCustomSearch, setUseCustomSearch] = useState<boolean>(initWithCustomSearch || false);
  const { noddiToast } = useNoddiToast();

  const selectedAddressLatLng = `${selectedAddress?.latitude}--${selectedAddress?.longitude}`;
  const hasAddresses = addresses && addresses.length > 0;

  const texts = translations ?? {
    couldNotFindAddress: commonTexts.couldNotFindAddress,
    addressNotFound: commonTexts.addressNotFound,
    tryAgainWithStreetNumber: commonTexts.tryAgainWithStreetNumber,
    chooseFromAnotherAddress: commonTexts.chooseFromAnotherAddress,
    chooseFromYourAddresses: commonTexts.chooseFromYourAddresses
  };

  return (
    <div style={style}>
      {hasAddresses && !useCustomSearch ? (
        <>
          <Select
            value={selectedAddressLatLng ?? ''}
            fullWidth
            onChange={(e) => {
              const address = extractAddressFromLatLng(e.target.value as string, addresses);

              if (!address) {
                noddiToast.error('Could not find address');
                return;
              }

              onSelectAddress(address);
            }}
          >
            {addresses?.map((address) => (
              <MenuItem
                key={`${address.latitude}--${address.longitude}`}
                value={`${address.latitude}--${address.longitude}`}
              >
                {getLongAddressName(address)}
              </MenuItem>
            ))}
          </Select>

          {!hideBottomOptions && (
            <div
              style={{ cursor: 'pointer', width: 'fit-content' }}
              onClick={() => {
                setUseCustomSearch(true);
              }}
            >
              <p style={{ fontSize: 14, textDecoration: 'underline' }}>{texts.chooseFromAnotherAddress}</p>
            </div>
          )}
        </>
      ) : (
        <>
          <GoogleMapsPlaceSearch
            translations={{
              couldNotFindAddress: texts.couldNotFindAddress,
              addressNotFound: texts.addressNotFound,
              tryAgainWithStreetNumber: texts.tryAgainWithStreetNumber
            }}
            placeIdFromUrl={placeIdFromUrl}
            setAddress={(address) => {
              onSelectAddress(address);
            }}
          />
          {hasAddresses && !hideBottomOptions && (
            <div
              style={{ cursor: 'pointer', width: 'fit-content' }}
              onClick={() => {
                setUseCustomSearch(false);
              }}
            >
              <p style={{ fontSize: 14, textDecoration: 'underline' }}>{texts.chooseFromYourAddresses}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { AddressPicker };
