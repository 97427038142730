import { Feature, LineString } from 'geojson';
import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { MapboxMap, NoddiButton, colors, useIsMobile } from 'noddi-ui';
import { MarkerProps } from 'noddi-ui/src/components/Elements/Map/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';

import RouteDetailItem from './RouteDetailItem';

const mapboxApiAccessToken = import.meta.env.VITE_APP_MAPBOX_API_KEY;

const RouteMap = ({ routeItems }: { routeItems: RouteItemForServiceWorker[] }) => {
  const [routeGeoJson, setRouteGeoJson] = useState<Feature<LineString>[] | null>(null);
  const isMobile = useIsMobile();

  const [canControlMap, setCanControlMap] = useState(isMobile ? false : true);
  function getMarkers(routeItems: RouteItemForServiceWorker[]) {
    return (
      routeItems.map((routeItem, idx) => ({
        id: routeItem.id,
        type: 'number' as const,
        markerText: `${idx + 1}`,
        color: routeItem?.booking.completedAt ? 'bg-signal-success' : 'bg-primary-darkPurple',
        isCompleted: !!routeItem.booking.completedAt,
        latitude: routeItem.address.latitude,
        longitude: routeItem.address.longitude,
        contentToShowOnClick: (
          <div className='max-w-50'>
            <RouteDetailItem routeItem={routeItem} index={idx} />
          </div>
        )
      })) ?? []
    );
  }

  // Function to fetch the route from Mapbox Directions API
  const getRoute = useCallback(async (markers: MarkerProps[]) => {
    const segments: Feature<LineString>[] = [];
    const completedMarkers = markers.filter((marker) => marker.isCompleted);

    for (let i = 0; i < markers.length - 1; i++) {
      const startMarker = markers[i]!;
      const endMarker = markers[i + 1]!;

      const coordinates = `${startMarker.longitude}, ${startMarker.latitude};${endMarker.longitude},${endMarker.latitude}`;
      const directionsUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${mapboxApiAccessToken}`;

      try {
        const response = await fetch(directionsUrl);
        const data = await response.json();

        const segmentGeoJson: Feature<LineString> = {
          type: 'Feature',
          geometry: data.routes[0].geometry,
          properties: {
            color: completedMarkers.length > i + 1 ? colors.signal.success : colors.primary.darkPurple
          }
        };

        segments.push(segmentGeoJson);
      } catch (error) {
        console.error('Error fetching segment from Mapbox Directions API', error);
      }
    }

    setRouteGeoJson(segments);
  }, []);

  const routeMarkers = useMemo(() => getMarkers(routeItems), [routeItems]);
  useEffect(() => {
    if (routeMarkers.length > 1) {
      getRoute(routeMarkers); // Fetch routes when markers are available
    }
  }, [routeMarkers, getRoute]);

  return (
    routeMarkers?.length > 0 && (
      <div>
        <MapboxMap
          fullscreenControl
          width='100%'
          height='300px'
          markers={routeMarkers}
          routeGeoJson={routeGeoJson}
          dragPan={canControlMap} // Disable single-finger dragging
          touchPitch={canControlMap} // Disable one-finger pitch movements
        />
        <div className='flex justify-end'>
          <NoddiButton
            variant='link'
            startIcon='LocationPin'
            className='-mr-3 flex sm:hidden'
            onClick={() => {
              setCanControlMap((prev) => !prev);
            }}
          >
            {canControlMap ? 'Freeze map' : 'Unfreeze map'}
          </NoddiButton>
        </div>
      </div>
    )
  );
};

export default RouteMap;
