import { Checkbox, FormControlLabel, Menu, MenuItem } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';

import { usePopover } from '../../../../hooks/usePopover';
import { NoddiDataTableMultiSelectFilter, SupportedFilterValues } from '../../../../types';
import FilterButton from './FilterButton';

const TableMultiSelectFilter = <T extends SupportedFilterValues>(props: NoddiDataTableMultiSelectFilter<T>) => {
  const { label, onChange, options, value = [], disabled = false } = props;

  const { anchorRef, handleClose, handleOpen, open } = usePopover<HTMLButtonElement>();

  const handleValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      let newValue = [...value];

      const selectedValue = event.target.value as T;
      const numberValue = Number(selectedValue);
      const isNumber = !isNaN(numberValue);
      const isChecked = event.target.checked;

      if (isNumber) {
        if (isChecked) {
          newValue.push(numberValue as T);
        } else {
          newValue = newValue.filter((item) => item !== numberValue);
        }
      } else {
        if (isChecked) {
          newValue.push(selectedValue);
        } else {
          newValue = newValue.filter((item) => item !== selectedValue);
        }
      }

      onChange(newValue);
    },
    [onChange, value]
  );

  return (
    <>
      <FilterButton
        anchorRef={anchorRef}
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        disabled={disabled}
      >
        {label}
      </FilterButton>
      <Menu anchorEl={anchorRef.current} onClose={handleClose} open={open}>
        {options.map((option, index) => (
          <MenuItem key={index}>
            <FormControlLabel
              control={
                <Checkbox checked={value.includes(option.value)} onChange={handleValueChange} value={option.value} />
              }
              label={option.label}
              sx={{
                flexGrow: 1,
                mr: 0
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export { TableMultiSelectFilter };
