import { MapContainer } from 'react-leaflet';
import styled from 'styled-components';

const NoddiMap = styled(MapContainer)`
  height: calc(80vh - 85px);
  border: 1px solid rgba(203, 208, 221, 1);
  border-radius: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
`;

export { NoddiMap };
