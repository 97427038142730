import { Menu, MenuItem } from '@mui/material';

import { usePopover } from '../../../../hooks/usePopover';
import { NoddiDataTableSingleSelectFilter, SupportedFilterValues } from '../../../../types';
import FilterButton from './FilterButton';

const TableSelectFilter = <T extends SupportedFilterValues>({
  label,
  onChange,
  options,
  value
}: NoddiDataTableSingleSelectFilter<T>) => {
  const { anchorRef, handleClose, handleOpen, open } = usePopover<HTMLButtonElement>();

  return (
    <>
      <FilterButton anchorRef={anchorRef} handleClose={handleClose} handleOpen={handleOpen} open={open}>
        {label}
      </FilterButton>
      <Menu anchorEl={anchorRef.current} open={open} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            selected={option.value === value}
            onClick={(_) => {
              onChange(option.value);
              handleClose();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export { TableSelectFilter };
