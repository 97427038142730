import { IconSvgProps } from './svgtype';

const LeftArrowAlt = ({ color, ...props }: IconSvgProps) => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M19.2 8.533 12.8 16l6.4 7.467'
    />
  </svg>
);
export { LeftArrowAlt };
