import { Typography } from '@mui/material';
import { commonTexts } from '../../commonTexts';

interface ForbiddenPageProps {
  translations?: {
    forbiddenAccess: string;
    youDoNotHavePermission: string;
  };
}

const ForbiddenPage = ({ translations }: ForbiddenPageProps) => {
  const texts = translations ?? {
    forbiddenAccess: commonTexts.forbiddenAccess,
    youDoNotHavePermission: commonTexts.youDoNotHavePermission
  };
  return (
    <div style={{ display: 'grid', placeContent: 'center', height: '100vh', textAlign: 'center' }}>
      <Typography variant='h1'>{texts.forbiddenAccess}</Typography>
      <Typography variant='subtitle1'>{texts.youDoNotHavePermission}</Typography>
    </div>
  );
};

// expect that all apps have same url to forbidden page
const forbiddenPageUrl = '/forbidden';

export { ForbiddenPage, forbiddenPageUrl };
