import type { ThemeOptions } from '@mui/material/styles/createTheme';

import type { Contrast } from '..';
import { createComponents } from './createComponents';
import { createPalette } from './createPalette';
import { createShadows } from './createShadows';

interface Config {
  contrast?: Contrast;
}

export const createOptions = ({ contrast }: Config): ThemeOptions => {
  const palette = createPalette({ contrast });
  const components = createComponents({ palette });
  const shadows = createShadows();

  return {
    components,
    palette,
    shadows
  };
};
