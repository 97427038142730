export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
export const phoneRegex = /^\+\d{8,15}$/;
export const hourMinuteRegex = /^\d{2}-\d{2}$/;

export const swedishLicensePlateRegex = /^[A-Z]{0,3}\d{0,2}[A-Z0-9]?$/;
export const norwegianLicensePlateRegex = /^[A-Z]{2}[0-9]{0,5}$/;
export const partialNorwegianLicensePlateRegex = /^[A-Z]{0,2}$/;

export const dateOfBirthRegex = /^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\d\d$/;

export const numericRegex = /^\d+$/;
