import _ from 'lodash';
import { AddressProps } from 'noddi-async/src/types';

export const isAddressEqual = (address1: AddressProps, address2: AddressProps) => {
  const fieldsToMatch = ['streetName', 'streetNumber', 'zipCode', 'city', 'countryCode'];
  const address1Fields = _.pick(address1, fieldsToMatch);
  const address2Fields = _.pick(address2, fieldsToMatch);
  return _.isEqual(address1Fields, address2Fields);
};

export function hasStreetNumber(address: AddressProps | undefined | null) {
  return address?.streetNumber !== undefined && address?.streetNumber !== null && address?.streetNumber !== '';
}

export const extractAddressFromLatLng = (latLng: string, addresses: AddressProps[]): AddressProps | undefined => {
  let address = null;

  // Split latitude and longitude using '--' as delimiter
  const [latStr, longStr] = latLng.split('--');
  const latitude = parseFloat(latStr?.trim() || '');
  const longitude = parseFloat(longStr?.trim() || '');

  address = addresses.find((address) => address.latitude === latitude && address.longitude === longitude);

  return address;
};
