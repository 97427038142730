import routes from '../appRoutes';

export const getNavItems = () => [
  {
    items: [
      {
        path: routes.routes.getPath(),
        title: 'Routes'
      }
    ]
  },
  {
    items: [
      {
        path: routes.calender.getPath(),
        title: 'Availability'
      }
    ]
  },
  {
    items: [
      {
        path: routes.profile.getPath(),
        title: 'Work profile'
      }
    ]
  },
  {
    items: [
      {
        path: routes.pickingList.getPath(),
        title: 'Picking list'
      }
    ]
  }
];
