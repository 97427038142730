import { BaseTextFieldProps, InputAdornment, MenuItem, Select, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Field, FieldProps } from 'formik';
import { LanguageCode, LanguageCodeType } from 'noddi-provider';
import { useIsMobile } from 'noddi-ui/src/hooks/useIsMobile';
import { CountryCode } from 'noddi-util';
import { useEffect, useRef } from 'react';
import {
  CountryData,
  CountryIso2,
  FlagImage,
  defaultCountries,
  parseCountry,
  usePhoneInput
} from 'react-international-phone';
import 'react-international-phone/style.css';
import styled from 'styled-components';

import { NoddiTextInput } from '../Inputs';
import { FormFieldErrorMessage } from './FormFieldErrorMessage';

const StyledSelect = styled(Select)`
  width: max-content;
  position: relative;
  left: -16px;

  fieldset {
    display: none;
  }

  &.Mui-focused:has(div[aria-expanded='false']) fieldset {
    display: block;
  }

  .MuiSelect-select {
    padding: 8px;
    padding-right: 24px !important;
  }

  svg {
    right: 0;
  }
`;

const StyledFlagImage = styled(FlagImage)`
  display: flex;
`;

const RightMarginFlagImage = styled(FlagImage)`
  margin-right: 8px;
`;

const StyledInputAdornment = styled(InputAdornment)`
  margin-right: 2px;
`;

const MenuProps = {
  PaperProps: {
    style: {
      height: '300px',
      width: '360px',
      marginTop: '10px'
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  }
};

interface NoddiPhoneInputProps extends Omit<BaseTextFieldProps, 'variant' | 'ref'> {
  value: string;
  onChange: (phone: string) => void;
  language?: LanguageCodeType;
}

const countryDataToMenuItem = (c: CountryData) => {
  const { iso2, name, dialCode } = parseCountry(c);

  return (
    <MenuItem key={iso2} value={iso2}>
      <RightMarginFlagImage iso2={iso2} />
      <Typography marginRight='8px'>{name}</Typography>
      <Typography color='gray'>+{dialCode}</Typography>
    </MenuItem>
  );
};

export const NoddiPhoneInput = ({ value, onChange, language, ...restProps }: NoddiPhoneInputProps) => {
  const getDefaultCountryCode = () => {
    switch (language) {
      case LanguageCode.ENGLISH:
      case LanguageCode.NORWEGIAN:
        return CountryCode.NORWAY;
      case LanguageCode.SWEDISH:
        return CountryCode.SWEDEN;
      default:
        return CountryCode.NORWAY;
    }
  };
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: getDefaultCountryCode().toLowerCase(),
    value,
    countries: defaultCountries,
    onChange: ({ phone }) => onChange(phone),
    disableDialCodeAndPrefix: true
  });
  const isMobile = useIsMobile();
  const hasMounted = useRef(false);
  const isDesktop = !isMobile;

  useEffect(() => {
    if (hasMounted.current || isDesktop) {
      //Add the timeout for proper inputRef value
      setTimeout(() => {
        if (inputRef?.current) {
          inputRef?.current.focus();
        }
      }, 50);
    } else {
      hasMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <NoddiTextInput
      color='primary'
      value={inputValue}
      onChange={handlePhoneValueChange}
      type='tel'
      ref={inputRef}
      InputProps={{
        startAdornment: (
          <StyledInputAdornment position='end'>
            <StyledSelect
              MenuProps={MenuProps}
              value={country.iso2}
              onChange={({ target: { value } }) => setCountry(value as CountryIso2)}
              renderValue={(value) => (
                <Stack direction='row' gap={1}>
                  <StyledFlagImage iso2={value as CountryIso2} />+{country.dialCode}
                </Stack>
              )}
            >
              {defaultCountries.map(countryDataToMenuItem)}
            </StyledSelect>
          </StyledInputAdornment>
        )
      }}
      {...restProps}
    />
  );
};

export const NoddiFormPhoneInput = ({ name, label }: { name: string; label: string }) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <div>
        <NoddiPhoneInput
          error={Boolean(form.touched[name] && form.errors[name])}
          value={field.value}
          label={label}
          onChange={(value: string) => form.setFieldValue(name, value)}
        />
        <FormFieldErrorMessage name={name} component='div' />
      </div>
    )}
  </Field>
);
