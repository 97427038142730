import { AddressProps } from 'noddi-async/src/types';
import * as Yup from 'yup';

type Translations = {
  nameIsRequired: string;
  streetNameIsRequired: string;
  streetNumberIsRequired: string;
  zipCodeIsRequired: string;
  cityIsRequired: string;
  countryIsRequired: string;
  latitudeIsRequired: string;
  longitudeIsRequired: string;
  descriptionIsRequired: string;
  placeTypeIsRequired: string;
};

export const getAddressYupObject = (translations: Translations) =>
  Yup.object({
    name: Yup.string().required(translations.nameIsRequired),
    streetName: Yup.string().required(translations.streetNameIsRequired),
    streetNumber: Yup.string().required(translations.streetNumberIsRequired),
    zipCode: Yup.string().required(translations.zipCodeIsRequired),
    city: Yup.string().required(translations.cityIsRequired),
    country: Yup.string().required(translations.countryIsRequired),
    latitude: Yup.number().required(translations.latitudeIsRequired),
    longitude: Yup.number().required(translations.longitudeIsRequired),
    description: Yup.string().required(translations.descriptionIsRequired),
    placeType: Yup.object({
      place_id: Yup.string().required(translations.placeTypeIsRequired),
      description: Yup.string().required(translations.placeTypeIsRequired),
      structured_formatting: Yup.object({
        main_text: Yup.string().required(translations.placeTypeIsRequired),
        secondary_text: Yup.string().required(translations.placeTypeIsRequired)
      }).required(translations.placeTypeIsRequired)
    }).required(translations.placeTypeIsRequired)
  });
export const getShortAddressName = (address: { streetName: string; streetNumber: string }) =>
  `${address.streetName} ${address.streetNumber}`;

export const getLongAddressName = (address: AddressProps) =>
  `${address.streetName} ${address.streetNumber}, ${address.zipCode} ${address.city}, ${address.country}`;
