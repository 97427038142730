import { URLKeys, noddiAsync } from 'noddi-async';
import { ApiErrorMessage, NoddiButton, NoddiDialog, NoddiLinearProgressLoader } from 'noddi-ui';
import { useState } from 'react';

import { getRouteItemsOfTypeBooking } from '../../../utils/routeItem';
import RouteMap from './RouteMap';
import RouteOverview from './RouteOverview';

const RouteCardItemDetails = ({ routeId }: { routeId: number }) => {
  const [isRouteOverviewOpen, setIsRouteOverviewOpen] = useState(false);

  const {
    data: routeItems,
    isPending: isRoutePending,
    isFetching: isRouteFetching,
    error: routeError
  } = noddiAsync.useGet({
    type: URLKeys.getRouteItemsForServiceWorker,
    input: { routeId }
  });

  if (isRoutePending || isRouteFetching) {
    return <NoddiLinearProgressLoader />;
  }

  if (routeError) {
    return <ApiErrorMessage error={routeError} />;
  }

  const onlyBookingRouteItems = getRouteItemsOfTypeBooking(routeItems);

  return (
    <div>
      <NoddiButton
        startIcon='MapArrows'
        className='p-0 !text-3'
        variant='link'
        onClick={() => setIsRouteOverviewOpen((prev) => !prev)}
      >
        Show route details
      </NoddiButton>

      <RouteMap routeItems={onlyBookingRouteItems} />

      <NoddiDialog
        fullWidth
        onClose={() => setIsRouteOverviewOpen(false)}
        open={isRouteOverviewOpen}
        title='Route details'
      >
        <RouteOverview routeItems={routeItems} />
      </NoddiDialog>
    </div>
  );
};

export default RouteCardItemDetails;
