import { Account as AccountNoddiUi } from 'noddi-ui';

import HomeContentWrapper from '../../layouts/HomeContentWrapper';

const EditContactInfo = () => (
  <HomeContentWrapper title='Edit contact info'>
    <AccountNoddiUi />
  </HomeContentWrapper>
);

export default EditContactInfo;
