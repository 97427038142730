import { IconSvgProps } from './svgtype';

const ArrowSquareRightCorner = ({ color, ...props }: IconSvgProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='m16 14 9-9m0 0h-5.344M25 5v5.344'
    />
    <path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={1.5}
      d='M25 15c0 4.714 0 7.071-1.465 8.535C22.072 25 19.714 25 15 25s-7.071 0-8.536-1.465C5 22.072 5 19.714 5 15s0-7.071 1.464-8.536C7.93 5 10.286 5 15 5'
    />
  </svg>
);
export default ArrowSquareRightCorner;
