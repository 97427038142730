import { CarWheelSetType, CarWheelSetTypes } from 'noddi-async/src/types';

export const getWheelSetTypeTranslation = (type: CarWheelSetType) => {
  switch (type.value) {
    case CarWheelSetTypes.Summer:
      return 'Summer';
    case CarWheelSetTypes.Winter:
      return 'Winter';
    default:
      return 'Unknown';
  }
};
