import { mapErrorCodeToUiMessage } from '../components';
import { NoddiAsyncError } from '../components/Elements/Errors/ErrorMessage';
import { errorCodes as errorCodesWithoutTrans } from '../errorCodes';
import { useToast } from './ToastProvider';

export const useNoddiToast = () => {
  const { addToast } = useToast();

  const success = (heading: string, description?: string) => addToast('success', heading, description);
  const error = (heading: string, description?: string) => addToast('error', heading, description);
  const warning = (heading: string, description?: string) => addToast('warning', heading, description);
  const asyncError = (e: NoddiAsyncError, errorCodes?: Record<string, string> & { genericErrorMessage: string }) =>
    error(
      mapErrorCodeToUiMessage({
        error: e,
        errorCodes: errorCodes ?? errorCodesWithoutTrans
      }),
      undefined
    );

  const noddiToast = { success, error, warning, asyncError };

  return { noddiToast };
};
