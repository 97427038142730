import { NoddiAsyncError } from 'noddi-async/src/types';

import { NoddiFeedbackBox } from '../../../molecules';
import { NoddiButton } from '../Buttons';
import { mapErrorCodeToUiMessage } from './ErrorMessage';
import { useCaptureException } from './utils';

export type ErrorPageProps = {
  errorTitle?: string;
  errorMessage?: string;
  apiError?: NoddiAsyncError | Array<NoddiAsyncError | null>;
  customCallback?: { ctaText: string; callback: () => void };
  errorCodes?: Record<string, string> & { genericErrorMessage: string };
  translations?: {
    title: string;
    description: string;
    tryAgain: string;
  };
};

const ErrorPage = ({
  errorMessage,
  errorTitle,
  apiError,
  customCallback,
  translations,
  errorCodes
}: ErrorPageProps) => {
  const errorToRender = Array.isArray(apiError) ? apiError.find((e) => e !== null) : apiError;
  const apiErrorMessage = errorToRender && errorCodes && mapErrorCodeToUiMessage({ error: errorToRender, errorCodes });

  const customErrorMessage = apiErrorMessage ?? errorMessage;

  function handleCustomCallback() {
    customCallback?.callback() || window.location.reload();
  }

  useCaptureException(customErrorMessage, 'ErrorPage');

  return (
    <div className='mx-auto flex min-h-[60vh] max-w-2xl flex-col items-center justify-center p-4 text-center md:p-10'>
      <NoddiFeedbackBox
        variant='error'
        heading={errorTitle ?? translations?.title ?? "We're sorry, an error has occurred"}
        description={
          customErrorMessage ??
          translations?.description ??
          'We are working on solving the issue, please try again later'
        }
      />

      <NoddiButton variant='primary' onClick={handleCustomCallback} style={{ marginTop: '20px' }}>
        {customCallback?.ctaText ?? translations?.tryAgain ?? 'Try again'}
      </NoddiButton>
    </div>
  );
};

export { ErrorPage };
