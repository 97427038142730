import { IconSvgProps } from './svgtype';

const Hammer = ({ color, ...props }: IconSvgProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M21.6597 11.2861L22.028 10.9179C22.3704 10.5754 22.5416 10.4042 22.6445 10.2261C22.9236 9.74271 22.9236 9.14718 22.6445 8.66381C22.5416 8.48569 22.3704 8.31446 22.028 7.97202C21.6855 7.62957 21.5143 7.45835 21.3362 7.35551C20.8528 7.07644 20.2573 7.07644 19.7739 7.35551C19.5958 7.45835 19.4246 7.62957 19.0821 7.97202L18.7139 8.34025M14.2951 18.6507L8.77166 24.1742C8.42921 24.5166 8.25799 24.6879 8.07987 24.7907C7.5965 25.0698 7.00097 25.0698 6.5176 24.7907C6.33948 24.6879 6.16825 24.5166 5.82581 24.1742C5.48337 23.8317 5.31214 23.6605 5.2093 23.4824C4.93023 22.999 4.93023 22.4035 5.2093 21.9201C5.31214 21.742 5.48337 21.5708 5.82581 21.2283L11.3493 15.7049M10.6128 14.9684L15.0316 19.3872C16.7674 21.123 17.6354 21.991 18.7139 21.991C19.7924 21.991 20.6604 21.123 22.3962 19.3872C24.1321 17.6513 25 16.7834 25 15.7049C25 14.6263 24.1321 13.7584 22.3962 12.0226L17.9774 7.60379C16.2416 5.86793 15.3737 5 14.2951 5C13.2166 5 12.3487 5.86793 10.6128 7.60379C8.87696 9.33965 8.00903 10.2076 8.00903 11.2861C8.00903 12.3646 8.87696 13.2326 10.6128 14.9684Z'
      stroke={color}
      strokeWidth='1.5'
    />
  </svg>
);

export { Hammer };
