import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { CapacityContribution, CapacityDay, ServiceWorker } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import {
  CapacityContributionAddresses,
  ErrorPage,
  NoddiButton,
  NoddiDialog,
  NoddiFeedbackBox,
  NoddiFormSelect,
  NoddiFormTextInput,
  NoddiTimeSlider,
  RegisterDateCalendar,
  RegisterWorkSelectedDates,
  getLongAddressName,
  useNoddiToast
} from 'noddi-ui';
import { HHMMtoMinutesSinceMidnight, hoursToSeconds, secondsToHours } from 'noddi-util';
import { useState } from 'react';

import { FormValues } from './FormValues';
import { getValidationSchema } from './validationSchema';

type Props = {
  capacityDays: CapacityDay[];
  serviceWorker: ServiceWorker;
  capacityContributions: CapacityContribution[];
  serviceWorkerId: number;
};

const r24 = {
  label: 'Ramstadsletta 24, 1363 Høvik, Norway',
  value: 5081
};

const RegisterNewWorkForm = ({ capacityDays, serviceWorker, capacityContributions, serviceWorkerId }: Props) => {
  const { noddiToast } = useNoddiToast();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { mutateAsync: createCapacityContribution } = noddiAsync.usePost({
    type: URLKeys.postCapacityContribution,
    queryConfig: {
      onSuccess: () => {
        invalidateQueryExactMatch({ urlKey: URLKeys.getRegisteredWorkerCapacityContributions });
        invalidateQueryExactMatch({ urlKey: URLKeys.getServiceWorker, input: { id: serviceWorkerId } });
        noddiToast.success('New availability was successfully registered');
      },
      onError: () => {
        noddiToast.error('Error when creating new availability');
      }
    }
  });

  const startTime = '08:00';
  const endTime = '16:00';
  const { attributes, addresses, licenseAreas } = serviceWorker;
  const { maxDuration, licenseAreaId } = attributes;

  const addressesOptions =
    addresses?.map((address) => ({
      label: getLongAddressName(address),
      value: address.id
    })) || [];

  if (!addressesOptions.some((option) => option.value === r24.value)) {
    addressesOptions.push(r24);
  }
  const licenseAreaOptions = licenseAreas?.map((licenseArea) => ({
    label: licenseArea.name,
    value: licenseArea.id
  }));

  const defaultAddress = addressesOptions?.length > 0 && addressesOptions[0]?.value;

  if (!defaultAddress && defaultAddress !== 0) {
    return <ErrorPage />;
  }

  // Cant use type submit inside dialog, so use onclick instead:
  const handleSubmit = async (values: FormValues) => {
    setIsDialogOpen(false);
    const { startAddress, endAddress, maxDuration, ...rest } = values;
    const capacityStartAddress = typeof startAddress === 'number' ? { startAddressId: startAddress } : { startAddress };
    const capacityEndAddress = typeof endAddress === 'number' ? { endAddressId: endAddress } : { endAddress };
    await createCapacityContribution({
      serviceWorkerId,
      ...rest,
      ...capacityStartAddress,
      ...capacityEndAddress,
      maxDuration: hoursToSeconds(maxDuration)
    });
  };
  // Empty onSubmit bc comment over.
  return (
    <div className='flex justify-center'>
      <Formik<FormValues>
        initialValues={{
          dates: [],
          licenseAreaId,
          startTime,
          endTime,
          maxDuration: secondsToHours(maxDuration),
          startAddress: defaultAddress,
          endAddress: defaultAddress
        }}
        validationSchema={getValidationSchema()}
        validateOnMount
        onSubmit={() => {}}
      >
        {({ isValid, isSubmitting, values, setFieldValue, errors }) => (
          <Form>
            <div className='flex flex-col items-center gap-2'>
              <div className='flex items-center justify-start gap-4 rounded-lg bg-primary-white p-3'>
                <div className='flex items-center gap-2'>
                  <div className='ml-1 size-3 rounded-full bg-primary-purple' />
                  <p> Existing availability</p>
                </div>
                <div className='flex items-center gap-2'>
                  <div className='ml-1 size-3 rounded-full bg-secondary-orange' />
                  <p> Unsaved availability</p>
                </div>
              </div>

              <div className='flex flex-col gap-2 rounded-xl bg-primary-white p-4'>
                <RegisterDateCalendar
                  capacityDays={capacityDays}
                  daysToRegister={values.dates}
                  capacityContributions={capacityContributions ?? []}
                  setDaysToRegister={(dates) => {
                    setFieldValue('dates', dates);
                  }}
                />
                <RegisterWorkSelectedDates
                  setDaysToRegister={(dates) => {
                    setFieldValue('dates', dates);
                  }}
                  daysToRegister={values.dates}
                />
                <NoddiDialog
                  title='Provide info for the selected dates'
                  onClose={() => setIsDialogOpen(false)}
                  open={isDialogOpen}
                >
                  <div className='flex flex-col gap-3'>
                    <CapacityContributionAddresses
                      addressesOptions={addressesOptions}
                      defaultAddress={defaultAddress}
                    />
                    <NoddiFormSelect
                      name='licenseAreaId'
                      label='Select you license area'
                      options={licenseAreaOptions}
                      fullWidth
                    />
                    <p className='mb-6'>Specify when you can work</p>
                    <NoddiTimeSlider
                      min={HHMMtoMinutesSinceMidnight('05:00')}
                      max={HHMMtoMinutesSinceMidnight('23:00')}
                      initTimeStartHHMM={startTime}
                      initTimeEndHHMM={endTime}
                      onChange={(dates) => {
                        if ('start' in dates && 'end' in dates) {
                          const { start, end } = dates;
                          setFieldValue('startTime', start);
                          setFieldValue('endTime', end);
                        }
                      }}
                    />
                    <NoddiFormTextInput
                      name='maxDuration'
                      type='number'
                      label='Specify the max number of hours you are willing to work'
                    />
                    <div className='flex w-full flex-col gap-2'>
                      <div>{errors?.dates && <NoddiFeedbackBox variant='warning' heading={errors.dates[0]} />}</div>
                      <div className='flex justify-end'>
                        <NoddiButton
                          disabled={!isValid}
                          loading={isSubmitting}
                          onClick={async () => {
                            await handleSubmit(values);
                            setFieldValue('dates', []);
                          }}
                        >
                          Save availability
                        </NoddiButton>
                      </div>
                    </div>
                  </div>
                </NoddiDialog>
              </div>
              <div className='flex w-full justify-end'>
                <NoddiButton
                  endIcon='Plus'
                  disabled={values.dates.length === 0}
                  loading={isSubmitting}
                  onClick={() => setIsDialogOpen(true)}
                >
                  Add availability
                </NoddiButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default RegisterNewWorkForm;
