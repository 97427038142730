import styled from 'styled-components';

type SimpleGridLayoutProps = {
  numColumns?: number;
};

export const SimpleGridLayout = styled.div<SimpleGridLayoutProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: ${(props) => (props.numColumns ? `repeat(${props.numColumns}, 1fr)` : 'repeat(2, 1fr)')};
    gap: 20px;
    align-items: stretch;
  }

  & > * {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
