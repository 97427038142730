import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { NoddiFeedbackBox } from 'noddi-ui';

interface RouteItemOnRouteStatusBoxProps {
  routeItem: RouteItemForServiceWorker;
  isNextOnRoute: boolean;
}
const RouteItemOnRouteStatusBox = ({ routeItem, isNextOnRoute }: RouteItemOnRouteStatusBoxProps) => {
  if (routeItem.booking.cancelledAt) {
    return <NoddiFeedbackBox description='This customer has cancelled their booking!' variant='error' />;
  }
  if (routeItem.booking.completedAt) {
    return <NoddiFeedbackBox description='This customer is completed!' variant='success' />;
  }

  if (!isNextOnRoute) {
    return <NoddiFeedbackBox description='This is not the next customer on your route!' variant='warning' />;
  }

  return null;
};

export { RouteItemOnRouteStatusBox };
