import { URLKeys, noddiAsync } from 'noddi-async';
import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import {
  ApiErrorMessage,
  IconName,
  NoddiButton,
  NoddiCircularLoader,
  NoddiCollapseCard,
  NoddiConfirmationDialog,
  NoddiFeedbackBox,
  NoddiIcon,
  NoddiLinearProgressLoader,
  colors,
  errorCodes,
  useNoddiToast
} from 'noddi-ui';
import {
  DateFormats,
  TimeUnit,
  differenceBetweenDates,
  format,
  fromSecondsToMinutes,
  intervalToDuration,
  secondsToMinutes
} from 'noddi-util';
import { useState } from 'react';

import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { openAddressInGoogleMaps } from '../../utils/navigation';
import NotificationBlock from './NotificationBlock';
import { roundToNearestMinuteInterval } from './utils';

interface IconRowProps {
  iconName: IconName;
  value: string;
  onClick?: () => void;
  className?: string;
}

const InfoRow = ({ iconName, value, onClick, className }: IconRowProps) => (
  <div className='mt-1 flex items-center gap-2' onClick={onClick}>
    <div>
      <NoddiIcon name={iconName} />
    </div>
    <p className={className}>{value}</p>
  </div>
);

interface BookingHeaderInfo {
  routeItem: RouteItemForServiceWorker;
}

const BookingHeaderInfo = ({ routeItem }: BookingHeaderInfo) => {
  const [isSMSAlreadySentDialogOpen, setIsSMSAlreadySentDialogOpen] = useState(false);
  const [isEarlySMSWarningDialogOpen, setIsEarlySMSWarningDialogOpen] = useState(false);
  const estimatedDrivingTimeInMinutes = fromSecondsToMinutes(routeItem.estimatedDrivingTimeInSeconds);
  const { noddiToast } = useNoddiToast();
  const [nextArrivalTime, setNextArrivalTime] = useState(roundToNearestMinuteInterval(estimatedDrivingTimeInMinutes));
  const [delayInMinutes, setDelayInMinutes] = useState(30);
  const [translatedUserComment, setTranslatedUserComment] = useState<string | null>(null);
  const {
    data: routeItemWithCustomerOverview,
    isPending: isRouteItemPending,
    error: routeItemError
  } = noddiAsync.useGet({
    type: URLKeys.getRouteItemCustomerOverview,
    input: { routeItemId: routeItem.id }
  });

  const { mutateAsync: translateUserComment, isPending: isTranslateUserCommentPending } = noddiAsync.usePost({
    type: URLKeys.postTranslateText,
    queryConfig: {
      onSuccess: (data) => {
        setTranslatedUserComment(data.data.translatedText);
      },
      onError: async (error) => {
        noddiToast.asyncError(error, errorCodes);
      }
    }
  });

  const { mutateAsync: sendDelaySMS, isPending: isSendDelaySMSPending } = noddiAsync.usePost({
    type: URLKeys.postDelayedMessage,
    queryConfig: {
      onSuccess: () => {
        noddiToast.success('Delayed SMS sent');
      },
      onError: async (error) => {
        noddiToast.asyncError(error, errorCodes);
      }
    }
  });

  const { mutateAsync: sendNextArrivalSMS, isPending: isSendNextArrivalSMSPending } = noddiAsync.usePost({
    type: URLKeys.postNextCustomerArrivalSMS,
    queryConfig: {
      onSuccess: () => {
        noddiToast.success('Next arrival SMS sent');
        invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteItemCustomerOverview,
          input: { routeItemId: routeItem.id }
        });
      },
      onError: async (error) => {
        noddiToast.asyncError(error, errorCodes);
      }
    }
  });

  if (isRouteItemPending) {
    return <NoddiCircularLoader />;
  }

  if (routeItemError) {
    return <ApiErrorMessage error={routeItemError} errorCodes={errorCodes} />;
  }

  const {
    address,
    estimatedServiceStart,
    estimatedDeparture,
    customerComments,
    serviceTimeInSeconds,
    customerPhoneNumber,
    adminComments,
    customerName,
    bookingItems,
    communicatedArrivalEnd,
    communicatedArrivalStart
  } = routeItemWithCustomerOverview;

  const numberOfCars = bookingItems.length;

  const numberOfCarsText = `${numberOfCars} ${numberOfCars === 1 ? 'car' : 'cars'}`;

  const expectedArrival = `${format(estimatedServiceStart, DateFormats.TIME)} - ${format(estimatedDeparture, DateFormats.TIME)}`;
  const nextArrivalTimeText = `Customer will get SMS that you will be arriving in ${nextArrivalTime} minutes`;
  const delayedTimeText = `Customer will get SMS that you are ${delayInMinutes} minutes delayed`;

  const serviceTime = intervalToDuration({ start: 0, end: serviceTimeInSeconds * 1000 });

  const totalRouteTime = serviceTime.hours
    ? `${serviceTime.hours} hours ${serviceTime.minutes} min`
    : `${serviceTime.minutes} min`;

  const hasWaitingTime = routeItem.estimatedWaitingTimeInSeconds > 0;
  const expectedCustomerArrival = `The customer expects you to arrive between ${format(communicatedArrivalStart, DateFormats.TIME)} and ${format(communicatedArrivalEnd, DateFormats.TIME)}`;

  const handleSendNextArrivalSMS = async () => {
    const estimatedArrivalMinutesNow = differenceBetweenDates(
      routeItemWithCustomerOverview.bookingTimeWindow.start,
      new Date(),
      'minutes' as TimeUnit
    );
    if (routeItemWithCustomerOverview?.nextArrivalSmsSentAt) {
      setIsSMSAlreadySentDialogOpen(true);
    } else if (estimatedArrivalMinutesNow > nextArrivalTime) {
      setIsEarlySMSWarningDialogOpen(true);
    } else {
      await sendNextArrivalSMS({ routeItemId: routeItem.id, numMinutes: nextArrivalTime });
    }
  };

  return (
    <div>
      <p className='text-6'>
        {address.streetName} {address.streetNumber}
      </p>
      <NoddiButton variant='link' className='!pl-0' onClick={() => openAddressInGoogleMaps(address)}>
        Open in google maps
      </NoddiButton>

      <InfoRow iconName='UserCircle' value={customerName} />
      <InfoRow
        iconName='Phone'
        onClick={() => window.open('tel:' + customerPhoneNumber)}
        value={customerPhoneNumber}
        className='cursor-pointer underline'
      />

      <InfoRow iconName='ClockCircle' value={expectedArrival} />
      {routeItem.booking.startedAt && !routeItem.booking.completedAt ? (
        <InfoRow iconName='Car' value={`${numberOfCarsText}, ${totalRouteTime}`} />
      ) : (
        <div className='mt-1 flex flex-col gap-2'>
          {bookingItems.map(({ car, orderLineDescriptions }) => (
            <div key={car.licensePlateNumber} className='flex flex-col'>
              <div className='flex items-center gap-2'>
                <div>
                  <NoddiIcon name='Car' />
                </div>
                <p className='font-semibold'>
                  {car.make} {car.model} - {car.licensePlateNumber}
                </p>
              </div>
              {orderLineDescriptions.map((description) => (
                <div key={description} className='ml-8 flex flex-col'>
                  <p className='text-3'>{description}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {adminComments && (
        <div className='mt-1 flex gap-2'>
          <div>
            <NoddiIcon name='ChatRounded' />
          </div>
          <p>Admin comment: {adminComments}</p>
        </div>
      )}
      {customerComments && (
        <div className='mt-1'>
          {isTranslateUserCommentPending ? (
            <NoddiLinearProgressLoader />
          ) : (
            <div className='flex gap-2'>
              <div>
                <NoddiIcon name='ChatRounded' />
              </div>

              <p>{translatedUserComment ?? customerComments}</p>
            </div>
          )}
          {translatedUserComment ? (
            <NoddiButton
              variant='link'
              className='pl-0'
              startIcon='AltArrowUp'
              onClick={() => setTranslatedUserComment(null)}
            >
              See original comment
            </NoddiButton>
          ) : (
            <NoddiButton
              variant='link'
              className='pl-0'
              startIcon='Globe'
              onClick={async () =>
                await translateUserComment({
                  text: customerComments,
                  targetLanguage: 'en'
                })
              }
            >
              Translate user comment
            </NoddiButton>
          )}
        </div>
      )}
      <p>{expectedCustomerArrival}</p>

      <div className='mt-2'>
        {hasWaitingTime && (
          <NoddiFeedbackBox
            description={`${secondsToMinutes(routeItem.estimatedWaitingTimeInSeconds)} minutes waiting time`}
            variant='warning'
          />
        )}
      </div>

      {!routeItem.booking.startedAt && (
        <div className='mt-4'>
          <NoddiCollapseCard
            backgroundColor={colors.secondary.coral70}
            preventHandleExpand
            sx={{ py: 2 }}
            header={<p className='text-4.5 font-semibold'>Send notification</p>}
            collapseBody={
              <div className='mt-4 flex flex-col gap-4'>
                <NotificationBlock
                  title='Next arrival?'
                  value={nextArrivalTime}
                  setValue={setNextArrivalTime}
                  isLoading={isSendNextArrivalSMSPending}
                  onClick={handleSendNextArrivalSMS}
                  helperText={nextArrivalTimeText}
                />
                {routeItemWithCustomerOverview?.nextArrivalSmsSentAt && (
                  <div className='flex items-center gap-1'>
                    <NoddiIcon name='Warning' />
                    <p className='text-3.5'>
                      Next arrival SMS was already sent at{' '}
                      {format(routeItemWithCustomerOverview?.nextArrivalSmsSentAt, DateFormats.TIME)}
                    </p>
                  </div>
                )}

                <hr className='text-systemColors-grey/20' />

                <NotificationBlock
                  title='Delayed?'
                  value={delayInMinutes}
                  setValue={setDelayInMinutes}
                  isLoading={isSendDelaySMSPending}
                  onClick={async () => await sendDelaySMS({ routeItemId: routeItem.id, numMinutes: delayInMinutes })}
                  helperText={delayedTimeText}
                />
              </div>
            }
          />
        </div>
      )}
      <NoddiConfirmationDialog
        title='Customer has already been notified'
        description='Are you sure you want to send another SMS about next arrival?'
        onConfirm={async () => {
          await sendNextArrivalSMS({ routeItemId: routeItem.id, numMinutes: nextArrivalTime });
        }}
        open={isSMSAlreadySentDialogOpen}
        onClose={() => setIsSMSAlreadySentDialogOpen(false)}
        cancelText='Cancel'
        confirmText='Send SMS'
      />
      <NoddiConfirmationDialog
        title='Too early to send SMS?'
        description={`Note that it may be too early to send the SMS, as there’s still time before the user’s booking time (${format(routeItemWithCustomerOverview?.bookingTimeWindow.start, DateFormats.TIME)})`}
        onConfirm={async () => {
          await sendNextArrivalSMS({ routeItemId: routeItem.id, numMinutes: nextArrivalTime });
        }}
        open={isEarlySMSWarningDialogOpen}
        onClose={() => setIsEarlySMSWarningDialogOpen(false)}
        cancelText='Cancel'
        confirmText='Send SMS'
      />
    </div>
  );
};

export default BookingHeaderInfo;
