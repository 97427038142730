import { Autocomplete, AutocompleteProps, ChipTypeMap } from '@mui/material';

const NoddiAutocomplete = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>(
  props: AutocompleteProps<Value, Multiple, DisableClearable, true, ChipComponent>
) => {
  return <Autocomplete freeSolo {...props} />;
};

export { NoddiAutocomplete };
