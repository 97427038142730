import { IconSvgProps } from './svgtype';

const Drop = ({ color, ...props }: IconSvgProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15.9996 3.58695L23.5463 11.1336C25.0387 12.6251 26.0553 14.5257 26.4675 16.595C26.8796 18.6643 26.6688 20.8094 25.8617 22.7589C25.0545 24.7084 23.6874 26.3747 21.9331 27.5471C20.1788 28.7195 18.1162 29.3452 16.0063 29.3452C13.8963 29.3452 11.8337 28.7195 10.0795 27.5471C8.32519 26.3747 6.95802 24.7084 6.15089 22.7589C5.34376 20.8094 5.13293 18.6643 5.54507 16.595C5.95722 14.5257 6.97381 12.6251 8.46627 11.1336L15.9996 3.58695Z'
      stroke={color}
      strokeWidth='1.5'
    />
  </svg>
);

export { Drop };
