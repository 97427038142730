import { IconSvgProps } from './svgtype';

const Alert = ({ color, ...props }: IconSvgProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15 9.25C15.4142 9.25 15.75 9.58579 15.75 10V16C15.75 16.4142 15.4142 16.75 15 16.75C14.5858 16.75 14.25 16.4142 14.25 16V10C14.25 9.58579 14.5858 9.25 15 9.25Z'
      fill={color}
    />
    <path
      d='M16 19C16 19.5523 15.5523 20 15 20C14.4477 20 14 19.5523 14 19C14 18.4477 14.4477 18 15 18C15.5523 18 16 18.4477 16 19Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.0574 4.25H14.9426C12.6342 4.24999 10.8252 4.24998 9.41371 4.43975C7.96897 4.63399 6.82895 5.03933 5.93414 5.93414C5.03933 6.82895 4.63399 7.96897 4.43975 9.41371C4.24998 10.8252 4.24999 12.6342 4.25 14.9426V15.0574C4.24999 17.3658 4.24998 19.1748 4.43975 20.5863C4.63399 22.031 5.03933 23.1711 5.93414 24.0659C6.82895 24.9607 7.96897 25.366 9.41371 25.5603C10.8252 25.75 12.6342 25.75 14.9426 25.75H15.0574C17.3658 25.75 19.1748 25.75 20.5863 25.5603C22.031 25.366 23.1711 24.9607 24.0659 24.0659C24.9607 23.1711 25.366 22.031 25.5603 20.5863C25.75 19.1748 25.75 17.3658 25.75 15.0574V14.9426C25.75 12.6342 25.75 10.8252 25.5603 9.41371C25.366 7.96897 24.9607 6.82895 24.0659 5.93414C23.1711 5.03933 22.031 4.63399 20.5863 4.43975C19.1748 4.24998 17.3658 4.24999 15.0574 4.25ZM6.9948 6.9948C7.56445 6.42514 8.33517 6.09825 9.61358 5.92637C10.9136 5.75159 12.6218 5.75 15 5.75C17.3782 5.75 19.0864 5.75159 20.3864 5.92637C21.6648 6.09825 22.4355 6.42514 23.0052 6.9948C23.5749 7.56445 23.9018 8.33517 24.0736 9.61358C24.2484 10.9136 24.25 12.6218 24.25 15C24.25 17.3782 24.2484 19.0864 24.0736 20.3864C23.9018 21.6648 23.5749 22.4355 23.0052 23.0052C22.4355 23.5749 21.6648 23.9018 20.3864 24.0736C19.0864 24.2484 17.3782 24.25 15 24.25C12.6218 24.25 10.9136 24.2484 9.61358 24.0736C8.33517 23.9018 7.56445 23.5749 6.9948 23.0052C6.42514 22.4355 6.09825 21.6648 5.92637 20.3864C5.75159 19.0864 5.75 17.3782 5.75 15C5.75 12.6218 5.75159 10.9136 5.92637 9.61358C6.09825 8.33517 6.42514 7.56445 6.9948 6.9948Z'
      fill={color}
    />
  </svg>
);

export { Alert };
