import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';

interface Props extends CheckboxProps {
  label?: string;
}
const NoddiCheckbox = (props: Props) => {
  return <FormControlLabel control={<Checkbox {...props} />} label={props.label} />;
};

export { NoddiCheckbox };
