import { isNotNullOrUndefined } from 'noddi-util';

import { NoddiDataTableFilterType } from '../../../../types';

const getActiveFilters = (filters: NoddiDataTableFilterType[]) => {
  return filters.filter((filter) => {
    const { value } = filter;
    return Array.isArray(value) ? value.length > 0 : isNotNullOrUndefined(value);
  });
};

export { getActiveFilters };
