import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { DateFormats } from 'noddi-util';

type Props = {
  name?: string;
  label?: string;
};

const NoddiDatePicker = <TDate,>(props: DatePickerProps<TDate> & React.RefAttributes<HTMLDivElement> & Props) => {
  const { name, label, format = DateFormats.SLASHED_DATE, ...rest } = props;

  return (
    <div>
      {label && <label htmlFor={name}>{label}</label>}
      <DatePicker format={format} sx={{ width: '100%' }} {...rest} />
    </div>
  );
};

export { NoddiDatePicker };
