import Stack from '@mui/material/Stack';

import { NavItem } from '../../../../types/navItem';
import { SideNavSection } from './SideNavSection';

export const SideNavItems = ({ navItems }: { navItems: NavItem[] }) => (
  <Stack
    component='nav'
    spacing={1}
    sx={{
      flexGrow: 1,
      px: 2
    }}
  >
    {navItems.map((section, index) => (
      <SideNavSection items={section?.items} key={index} />
    ))}
  </Stack>
);
