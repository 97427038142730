import { IconSvgProps } from './svgtype';

const AltArrowDown = ({ color, ...props }: IconSvgProps) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M23.4667 12.8L16 19.2L8.53333 12.8'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { AltArrowDown };
