import { Card, Stack } from '@mui/material';
import { RouteStatusForAdmin } from 'noddi-async/src/types';
import styled from 'styled-components';

import { useIsMobile } from '../../../../hooks';
import { RouteItemTypes, UniqueCapacityContributionsWithRouteItems } from '../types';
import { RouteHeader } from './RouteHeader';
import { RouteItemCard } from './RouteItem/RouteItemCard';

type RouteSwimLaneProps = {
  routeItemsWithContribution: UniqueCapacityContributionsWithRouteItems;
  isSelectedSolution: boolean;
  route: RouteStatusForAdmin;
  translations: {
    canceled: string;
    startsTooLate: string;
    cars: string;
    unableToComplete: string;
    break: string;
    thisRouteIsNotValid: string;
    customerComments: string;
    adminComments: string;
    workerComments: string;
    waitingTime: string;
    booking: string;
  };
};

const getRouteStartsAt = (routeItemsWithContribution: RouteStatusForAdmin) => {
  if (routeItemsWithContribution.routeItems.length === 0) {
    return;
  }

  const first = routeItemsWithContribution.routeItems[0];
  if (!first) {
    return;
  }

  if (first.type !== RouteItemTypes.Depot) {
    return;
  }

  return first.estimatedServiceStart;
};

const RouteSwimLane = ({ routeItemsWithContribution, isSelectedSolution, route, translations }: RouteSwimLaneProps) => {
  const { id: routeId, delayInSeconds, completedAt, startedAt } = route;
  const { isValid } = route.summary;
  const delayInMinutes = Math.round(delayInSeconds / 60) / 1;
  const isMobile = useIsMobile();

  return (
    <Card sx={{ boxShadow: isMobile ? 'none !important' : 'inherit', padding: 3 }}>
      <RouteHeader
        completedAt={completedAt}
        delayInMinutes={delayInMinutes}
        isSelectedSolution={isSelectedSolution}
        isValid={isValid}
        startedAt={startedAt}
        routeStartAt={getRouteStartsAt(route)}
        translations={translations}
      />
      <StyledStack direction='row' gap={2} sx={{ overflow: 'auto' }}>
        {routeItemsWithContribution.routeItems.map((routeItem) => {
          if (routeItem.type === RouteItemTypes.Depot) {
            return;
          }
          return (
            <RouteItemCard
              translations={translations}
              key={routeItem.id}
              routeItem={routeItem}
              isSelectedSolution={isSelectedSolution}
              delayInMinutes={delayInMinutes}
              routeId={routeId}
            />
          );
        })}
      </StyledStack>
    </Card>
  );
};

export { RouteSwimLane };

const StyledStack = styled(Stack)`
  padding-bottom: 8px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
