import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import styled from 'styled-components';

const FullWidthContainer = styled.div`
  width: 100%;
`;

type Variant = 'small' | 'default';

const variantStyle: Record<Variant, { marginTop: number; marginBottom: number }> = {
  small: {
    marginTop: 1,
    marginBottom: 2
  },
  default: {
    marginTop: 4,
    marginBottom: 3
  }
};

export const SectionSpacer = ({
  children,
  fullWidth = false,
  variant = 'default'
}: {
  children: ReactNode;
  fullWidth?: boolean;
  variant?: Variant;
}) => {
  const stack = (
    <Stack spacing={3} marginTop={variantStyle[variant].marginTop} marginBottom={variantStyle[variant].marginBottom}>
      {children}
    </Stack>
  );

  if (fullWidth) {
    return <FullWidthContainer>{stack}</FullWidthContainer>;
  }

  return stack;
};
