import * as Yup from 'yup';

const tireSchema = Yup.object().shape({
  frontLeft: Yup.number()
    .min(0, 'Must be at least 0')
    .max(14, 'Should be at most 14')
    .required('Please fill in a value'),
  frontRight: Yup.number()
    .min(0, 'Must be at least 0')
    .max(14, 'Should be at most 14')
    .required('Please fill in a value'),
  rearLeft: Yup.number()
    .min(0, 'Must be at least 0')
    .max(14, 'Should be at most 14')
    .required('Please fill in a value'),
  rearRight: Yup.number()
    .min(0, 'Must be at least 0')
    .max(14, 'Should be at most 14')
    .required('Please fill in a value')
});

export function getCarTireMeasurementsSchema() {
  return Yup.object().shape({
    summer: tireSchema,
    winter: tireSchema
  });
}

export type CarMeasurementFormValues = Yup.InferType<ReturnType<typeof getCarTireMeasurementsSchema>>;
