import { UserGroupListItem } from '../userGroup';
import { CarListItem } from './car';
import { CarWheel } from './carWheel';
import { CarWheelSetSpecifications } from './carWheelSetSpecifications';

export const CarWheelSetTypes = {
  ['Summer']: 0,
  ['Winter']: 1
} as const;

export type CarWheelSetType = {
  value: number;
  label: keyof typeof CarWheelSetTypes;
};

export type CarWheelSetListItemType = {
  id: number;
  type: CarWheelSetType;
  isStaggered: boolean;
  workAppUrl: string;
};

export interface CarWheelSet {
  id: number;
  type: CarWheelSetType;
  wheels: CarWheel[];
  isStaggered: boolean;
  carWheelSetSpecifications: CarWheelSetSpecifications;
  fullName: string;
  workAppUrl: string;
}

export type CarWheelSetPatchInput = Omit<
  CarWheelSet,
  'type' | 'wheels' | 'carWheelSetSpecifications' | 'fullName' | 'workAppUrl'
> & {
  carWheelSetSpecificationsId?: number | null;
};

export type CarWheelSetStorageInformation = {
  id: number;
  type: CarWheelSetType;
  fullName: string;
  wheels: CarWheel[];
  car: CarListItem;
  userGroup: UserGroupListItem;
};
