import { GridRowSelectionModel } from '@mui/x-data-grid';
import { ReactNode } from 'react';

export type NestedMenuProps = {
  anchorEl: HTMLElement | null;
  resetAnchorEl: () => void;
};

export type DropDownRenderProps = {
  icon?: React.ReactElement;
  text: string;
};

export type DataGridDropdownItem = {
  render: DropDownRenderProps | React.ReactElement;
  action: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, selectedRow: GridRowSelectionModel) => void;
  actionForSingleItemOnly: boolean;
  disabled?: boolean;
  closeWhenClicked?: boolean;
  nestedMenuProps?: NestedMenuProps;
};

export type SupportedFilterValues = string | number | boolean;

export type NoddiDataTableFilterType =
  | NoddiDataTableMultiSelectFilter<string>
  | NoddiDataTableMultiSelectFilter<number>
  | NoddiDataTableSingleSelectFilter<string>
  | NoddiDataTableSingleSelectFilter<number>
  | NoddiDataTableSingleSelectFilter<boolean>
  | NoddiDataTableTimePickerFilter
  | NoddiDataTableTimePickerStaticFilter;

export const dataTableFilterTypes = {
  singleSelect: 'singleSelect',
  multiSelect: 'multiSelect',
  timePicker: 'timePicker',
  timePickerStatic: 'timePickerStatic'
} as const;

export type FilterTypes = keyof typeof dataTableFilterTypes;

export type NoddiDataTableFilter<T extends SupportedFilterValues> =
  | NoddiDataTableSingleSelectFilter<T>
  | NoddiDataTableMultiSelectFilter<T>
  | NoddiDataTableTimePickerFilter
  | NoddiDataTableTimePickerStaticFilter;

export type NoddiDataTableTimePickerFilter = {
  type: 'timePicker';
  name: string;
  onChange: (date: Date | null) => void;
  clearFilter: () => void;
  value: Date | null;
  label: ReactNode;
};

export type NoddiDataTableTimePickerStaticFilter = {
  type: 'timePickerStatic';
  onChange: (date: Date) => void;
  value: Date;
  label: ReactNode;
  name: string;
  disabled?: boolean;
};

export type NoddiDataTableSingleSelectFilter<T extends SupportedFilterValues> = {
  type: 'singleSelect';
  label: ReactNode;
  value: T | null;
  options: { label: React.ReactNode; value: T }[];
  clearFilter: () => void;
  onChange: (value: T) => void;
  name: string;
  disabled?: boolean;
};

export type NoddiDataTableMultiSelectFilter<T extends SupportedFilterValues> = {
  type: 'multiSelect';
  label: ReactNode;
  value: T[];
  clearFilter: (value: T[] | undefined) => void;
  options: { label: React.ReactNode; value: T }[];
  onChange: (values: T[]) => void;
  name: string;
  disabled?: boolean;
};
