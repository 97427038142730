import { NoddiIcon } from 'noddi-ui';
import { useState } from 'react';

const BASE_URL = import.meta.env.VITE_APP_BE_URL;
const isLocalEvn = import.meta.env.VITE_APP_LOCAL_ENV;

const APIBanner = () => {
  const isTestBackend = BASE_URL.includes('https://dev.api.noddi.no');
  const isProdBackend = BASE_URL.includes('https://api.noddi.no');

  const isLocalEnvAndProdBackend = isLocalEvn && isProdBackend;

  const [showBanner, setShowBanner] = useState(isTestBackend || isLocalEnvAndProdBackend);

  if (!showBanner) {
    return null;
  }
  return (
    <div
      className={`fixed left-0 top-0 z-[9999] flex size-fit rounded-lg ${isLocalEnvAndProdBackend ? 'bg-signal-danger' : 'bg-signal-success50'} p-[0.5px]`}
    >
      <div className='flex gap-2'>
        <div className='font-bold'>{isLocalEnvAndProdBackend ? 'PROD BE' : 'TEST BE'}</div>
        <div className='cursor-pointer'>
          <NoddiIcon name='Cross' onClick={() => setShowBanner(false)} />
        </div>
      </div>
    </div>
  );
};

export { APIBanner };
