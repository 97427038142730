import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { UserGroupProps } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { useEffect, useState } from 'react';

import { colors } from '../../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../../atoms/NoddiIcon';
import { noddiApps } from '../../../../types';
import { SideNavDivider } from './SideNavDivider';

const appConsumer = import.meta.env.VITE_APP_CONSUMER;

const stringAvatar = ({ str, isLoading }: { str: string; isLoading: boolean }) => {
  let displayStr;
  if (isLoading) {
    displayStr = '?';
  } else {
    const words = str.split(' ');
    const firstLetters = words.map((word) => word.charAt(0));
    const avatarStr = firstLetters.join('').toUpperCase();
    displayStr = avatarStr.length > 3 ? avatarStr.slice(0, 3) + '..' : avatarStr;
  }

  const fontSize = () => {
    if (displayStr.length > 3) {
      return '7px';
    } else if (displayStr.length > 2) {
      return '10px';
    } else {
      return '14px';
    }
  };

  return {
    children: displayStr,
    sx: {
      borderRadius: '4px',
      backgroundColor: colors.primary.darkPurple30,
      height: '25px',
      width: '25px',
      fontSize: fontSize(),
      marginRight: '9px'
    }
  };
};

interface SelectComponentProps {
  handleClearDataWhenImpersonating?: () => void;
}
const SelectComponent = ({ handleClearDataWhenImpersonating }: SelectComponentProps) => {
  const { userData, updateUserData, updateImpersonatedData, impersonatedUser } = useAuthContext();

  const [userGroupsToShow, setUserGroupsToShow] = useState<UserGroupProps[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleChange = (e: SelectChangeEvent<string | number>) => {
    if (!userData) {
      throw new Error('User data is not defined');
    }

    const newUserData = userData.user;
    newUserData.userGroups = userData.user.userGroups.map((ug) => {
      ug.isSelected = ug.id.toString() === e.target.value.toString();
      return ug;
    });

    if (impersonatedUser) {
      updateImpersonatedData({ ...userData, user: newUserData });
    } else {
      updateUserData({ ...userData, user: newUserData });
    }
    handleClearDataWhenImpersonating?.();
  };

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (appConsumer === noddiApps.org) {
      setUserGroupsToShow(userData.user.userGroups.filter((ug) => ug.isOrganization));
    } else {
      setUserGroupsToShow(userData.user.userGroups);
    }
  }, [userData]);

  const selectedUserGroup = userGroupsToShow.find((ug) => ug.isSelected);
  const selectedUserGroupName = selectedUserGroup?.name ?? '...';

  const hasMultipleUserGroups = !!(userGroupsToShow && userGroupsToShow.length > 1);

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          cursor: hasMultipleUserGroups ? 'pointer' : 'default',
          paddingY: '20px'
        }}
        onClick={() => {
          if (hasMultipleUserGroups) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <Stack direction='row' alignItems='center'>
          <Avatar
            {...stringAvatar({
              str: selectedUserGroupName,
              isLoading: !selectedUserGroup
            })}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
          <p
            style={{
              fontSize: '14px',
              fontWeight: 400,
              marginBottom: 0,
              marginTop: '2px',
              maxWidth: '173px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {selectedUserGroupName}
          </p>
        </Stack>
        {hasMultipleUserGroups && <NoddiIcon name='AltArrowDown' color={colors.primary.white} className='ml-2' />}
      </Stack>

      <Select
        sx={{ visibility: 'hidden', height: 0 }}
        open={isOpen}
        value={selectedUserGroup?.id ?? ''}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={(e) => handleChange(e)}
      >
        {userGroupsToShow.map((userGroup) => (
          <MenuItem
            key={userGroup.id}
            value={userGroup.id}
            selected={userGroup.isSelected}
            sx={{
              '&:hover': {
                backgroundColor: colors.systemColors.outlineStroke,
                '&.Mui-selected': {
                  backgroundColor: colors.systemColors.outlineStroke
                }
              },
              '&.Mui-selected': {
                backgroundColor: colors.primary.darkPurple30
              }
            }}
          >
            {userGroup.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export const SideNavHeader = ({
  handleClearDataWhenImpersonating
}: {
  handleClearDataWhenImpersonating?: () => void;
}) => {
  return (
    <Stack spacing={1} sx={{ p: 3, color: colors.primary.white, marginTop: '10px' }}>
      <SelectComponent handleClearDataWhenImpersonating={handleClearDataWhenImpersonating} />
      <SideNavDivider />
    </Stack>
  );
};
