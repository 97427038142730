import { AddressProps, ShortAddress } from 'noddi-async/src/types';

export function openAddressInGoogleMaps(address?: AddressProps | ShortAddress) {
  window.open(
    'https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination=' +
      address?.latitude +
      ',' +
      address?.longitude,
    '_blank'
  );
}
