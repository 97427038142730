import { SxProps } from '@mui/material';
import { ChangeEvent } from 'react';

import { NoddiIcon } from '../../../../atoms/NoddiIcon';
import { NoddiTextInput } from '../../Inputs';

interface Props {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  sx?: SxProps;
}

export const NoddiSearchBar = ({ handleChange, placeholder, sx }: Props) => {
  return (
    <NoddiTextInput
      InputProps={{
        startAdornment: <NoddiIcon name='Magnifier' size='medium' className='mr-4' />
      }}
      fullWidth
      placeholder={placeholder}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
      sx={{
        flexGrow: 1,
        ...sx
      }}
    />
  );
};
