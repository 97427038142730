import { Slider, SliderProps, SxProps } from '@mui/material';
import { DateFormats, HHMMtoMinutesSinceMidnight, addMinutes, format, getStartOfToday } from 'noddi-util';
import { useCallback, useMemo, useState } from 'react';
import { cn } from '../../../helpers/utils';

type TimeProps = { start: string; end: string };
type NoddiTimeSliderProps = {
  onChange: ({ start, end }: TimeProps) => void;
  min?: number;
  max?: number;
  initTimeStartHHMM?: string;
  initTimeEndHHMM?: string;
  step?: number;
  label?: string;
  sx?: SxProps;
  className?: string;
};

const NoddiTimeSlider = ({
  onChange,
  min,
  max,
  step,
  initTimeEndHHMM,
  initTimeStartHHMM,
  label,
  className,
  ...rest
}: NoddiTimeSliderProps & SliderProps) => {
  const initTimeStart = initTimeStartHHMM
    ? HHMMtoMinutesSinceMidnight(initTimeStartHHMM)
    : HHMMtoMinutesSinceMidnight('08:30');
  const initTimeEnd = initTimeEndHHMM
    ? HHMMtoMinutesSinceMidnight(initTimeEndHHMM)
    : HHMMtoMinutesSinceMidnight('21:30');

  const [timeDuration, setTimeDuration] = useState<number[]>([initTimeStart, initTimeEnd]);

  const formatTime = (value: number) => {
    return format(addMinutes(getStartOfToday(), value), DateFormats.TIME);
  };

  const marks = useMemo(() => {
    const result = [];
    for (let i = 0; i <= 1440; i++) {
      result.push({ value: i, label: formatTime(i) });
    }
    return result;
  }, []);

  const valueLabelFormat = useCallback(
    (value: number) => {
      return marks.find((mark) => mark?.value === value)?.label.toString() ?? '';
    },
    [marks]
  );

  const handleChange = (_: Event, newValue: number | number[], _activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    setTimeDuration(newValue);
    const start = newValue[0];
    const end = newValue[1];

    if (!start || !end) {
      return;
    }
    const dto = { start: formatTime(start), end: formatTime(end) };
    return onChange(dto);
  };

  return (
    <div className={cn('flex flex-col gap-10', className)}>
      {label && <label>{label}</label>}
      <Slider
        value={timeDuration}
        onChange={handleChange}
        valueLabelDisplay='on'
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        size='medium'
        min={min ?? HHMMtoMinutesSinceMidnight('07:00')}
        max={max ?? HHMMtoMinutesSinceMidnight('23:00')}
        step={step ?? 30}
        {...rest}
      />
    </div>
  );
};

export { NoddiTimeSlider };
