import { useQuery } from '@tanstack/react-query';
import { NoddiAsyncError, SalesCar, SalesItemPropsType } from 'noddi-async/src/types';

import useAxiosPrivate from '../../adapters/xhr/axiosPrivate';
import { ApiEndpointIdentifier } from '../../constant/apiRoute';
import { getEndpointUrl } from '../../helper';

export interface CarSalesItemProps {
  car_index: string;
  sales_items: SalesItemPropsType[];
}

type Props = {
  license_area_id: number;
  car: SalesCar;
  enabled: boolean;
  fetchForNewCar: boolean;
};

export type SalesItemDto = {
  id: number;
  name: string;
  price: number;
  description: string;
};

/**
 *
 * We are using this hook to get the sales items for the car. Depending on if we
 * want to add sales items to an existing booking or if we want to add sales items
 * to a new car, we are using different endpoints. This is controlled trough the
 * fetchForNewCar boolean.
 */
const useGetSalesItems = ({ license_area_id, car, enabled = true, fetchForNewCar = false }: Props) => {
  const axiosPrivate = useAxiosPrivate();

  const carValues = {
    index: car.index,
    size: car.carSize,
    height: car.height,
    m3: car.m3,
    weight: car.weight
  };
  const lisencePlateNumber = car.licensePlateNumber;

  const queryKey = fetchForNewCar
    ? [ApiEndpointIdentifier.SALES_ITEMS_EXTRA, lisencePlateNumber]
    : [ApiEndpointIdentifier.GET_AVAILABLE_SALES_ITEMS, lisencePlateNumber];
  const queryFn = fetchForNewCar
    ? async () => {
        const { data } = await axiosPrivate.get<CarSalesItemProps[]>(
          getEndpointUrl(ApiEndpointIdentifier.GET_AVAILABLE_SALES_ITEMS, {
            // this endpoint expects an array of license area ids and an array of car values
            licenseAreaIds: [license_area_id],
            carValues: JSON.stringify([carValues])
          })
        );

        const salesItemsForCar = data.find((salesItem) => {
          return salesItem.car_index === carValues.index;
        })?.sales_items;

        // addons are really just sales items, so we map them here already and treat them that way
        const addons = salesItemsForCar?.map((salesItem) => salesItem.addons).flat();

        return salesItemsForCar?.concat(addons ?? []) ?? [];
      }
    : async () => {
        // @ts-ignore
        const car_size_id = car.carSizeId;
        const { data } = await axiosPrivate.get(
          getEndpointUrl(ApiEndpointIdentifier.SALES_ITEMS_EXTRA, {
            car_size_id,
            licenseAreaIds: license_area_id
          })
        );
        return data;
      };

  return useQuery<SalesItemDto[], NoddiAsyncError>({ queryKey, queryFn, enabled });
};

export default useGetSalesItems;
