import { SxProps } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles/createTheme';
import { ReactNode, useEffect, useState } from 'react';

import { NoddiIcon } from '../../../atoms/NoddiIcon';

interface Props {
  header: ReactNode;
  collapseBody: ReactNode;
  sx?: SxProps<Theme> | undefined;
  borderColor?: string;
  backgroundColor?: string;
  mountAsExpanded?: boolean;
  // use this if you need to do custom onClick logic inside collapse body
  preventHandleExpand?: boolean;
  ExpandHelperElement?: React.ReactNode;
  expandedChanged?: (expanded: boolean) => void;
  hideChevron?: boolean;
}

export function NoddiCollapseCard({
  header,
  collapseBody,
  sx,
  borderColor = 'transparent',
  backgroundColor = 'white',
  mountAsExpanded = false,
  preventHandleExpand = false,
  ExpandHelperElement,
  expandedChanged,
  hideChevron = false
}: Props) {
  const [isExpanded, setIsExpanded] = useState(mountAsExpanded);
  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    expandedChanged?.(isExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const borderProps = borderColor === 'transparent' ? {} : { border: `1px solid ${borderColor}` };
  return (
    <Stack
      onClick={preventHandleExpand ? undefined : handleExpand}
      p={3}
      borderRadius={3}
      sx={{
        backgroundColor,

        ...borderProps,
        ...sx
      }}
    >
      <Stack>
        <Stack
          onClick={preventHandleExpand ? handleExpand : undefined}
          direction='row'
          justifyContent='space-between'
          gap={1}
          alignItems='center'
          style={{
            width: '100%',
            cursor: 'pointer',
            gap: hideChevron ? '0px' : 'inherit'
          }}
        >
          {header}
          <Stack justifyContent='flex-end' direction='row' alignItems='center'>
            {ExpandHelperElement ?? null}
            {!hideChevron && (
              <>
                {isExpanded ? (
                  <NoddiIcon size='medium' name='AltArrowUp' />
                ) : (
                  <NoddiIcon name='AltArrowDown' size='medium' />
                )}
              </>
            )}
          </Stack>
        </Stack>
        <Collapse in={isExpanded} timeout='auto' unmountOnExit>
          {collapseBody}
        </Collapse>
      </Stack>
    </Stack>
  );
}
