import { emailRegex } from 'noddi-util';
import * as yup from 'yup';

const userRegistrationFormSchema = () =>
  yup.object({
    name: yup.string().required('This field is required').min(2, 'This field must be at least 2 characters long'),

    email: yup.string().required('This field is required').matches(emailRegex, 'Need to be a valid email'),

    address: yup.object().shape({
      streetName: yup.string().required('Need to be an address with street name'),
      streetNumber: yup.string().required('Need to be an address with street name')
    }),
    password: yup.string().required('This field is required').min(8, 'Password must be at least 8 characters long'),
    confirmPassword: yup
      .string()
      .required('This field is required')
      .oneOf([yup.ref('password')], 'Passwords must match')
  });

export default userRegistrationFormSchema;
