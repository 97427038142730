import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { NoddiButton, NoddiDialog } from 'noddi-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import RouteOverview from '../../components/ui/RouteCard/RouteOverview';
import { getRouteItemsOfTypeBooking } from '../../utils/routeItem';
import TimeStatusChip from './TimeStatusChip';

interface RouteHeaderProps {
  routeItems: RouteItemForServiceWorker[];
  routeItemId: number;
  routeId: number;
}
const RouteHeader = ({ routeItems, routeItemId, routeId }: RouteHeaderProps) => {
  const navigate = useNavigate();
  const [isRouteOverviewOpen, setIsRouteOverviewOpen] = useState(false);

  const onlyBookingRouteItems = getRouteItemsOfTypeBooking(routeItems);

  // we add 1 to the number to adjust for 0-based index
  const routeItemIndex = onlyBookingRouteItems.findIndex((item) => item.id === routeItemId) + 1;
  const numberOfRouteItems = onlyBookingRouteItems.length;
  const customerOverviewStatusText = `Customer ${routeItemIndex} out of ${numberOfRouteItems}`;

  return (
    <>
      <div className='my-4'>
        <div className='mb-3 flex items-center justify-between'>
          <TimeStatusChip routeId={routeId} />
          <NoddiButton variant='secondary' onClick={() => setIsRouteOverviewOpen((prev) => !prev)}>
            Show route overview
          </NoddiButton>
        </div>
        <p className='font-bold text-4 text-primary-darkPurple'>{customerOverviewStatusText}</p>
      </div>
      <NoddiDialog
        fullWidth
        onClose={() => setIsRouteOverviewOpen(false)}
        open={isRouteOverviewOpen}
        title='Route details'
      >
        <RouteOverview
          routeItems={routeItems}
          onNavigateToRouteItem={(routeItemId) => {
            setIsRouteOverviewOpen(false);
            navigate(routes.routeItemOnRoute.getPath({ routeId, routeItemId }));
          }}
        />
      </NoddiDialog>
    </>
  );
};

export default RouteHeader;
