import { secondsToMinutes } from 'date-fns';
import { RouteItemForServiceWorker } from 'noddi-async/src/types';

export function getCurrentRouteItem(routeItems: RouteItemForServiceWorker[]) {
  // We only want routeItems of type booking
  const onlyBookingRouteItems = getRouteItemsOfTypeBooking(routeItems);
  return onlyBookingRouteItems.find((routeItem) => {
    return !routeItem.booking.completedAt && !routeItem.booking.cancelledAt;
  });
}

// get next route item after upcoming route item
export function getNextRouteItem(
  routeItems: RouteItemForServiceWorker[],
  upcomingRouteItem: RouteItemForServiceWorker | undefined
) {
  if (!upcomingRouteItem) {
    return null;
  }
  // We only want routeItems of type booking
  const onlyBookingRouteItems = getRouteItemsOfTypeBooking(routeItems);
  const upcomingRouteItemIndex = onlyBookingRouteItems.findIndex((routeItem) => routeItem.id === upcomingRouteItem.id);

  // make sure there is a next route item after upcoming route item
  if (upcomingRouteItemIndex === routeItems.length - 1) {
    return null;
  }
  return onlyBookingRouteItems[upcomingRouteItemIndex + 1];
}

export function getFinishedRouteItems(routeItems: RouteItemForServiceWorker[]) {
  // We only want routeItems of type booking
  const onlyBookingRouteItems = getRouteItemsOfTypeBooking(routeItems);
  return onlyBookingRouteItems.filter((routeItem) => {
    return routeItem.booking.completedAt || routeItem.booking.cancelledAt;
  });
}

export function isRouteWithinTimeThreshold(delayInSeconds: number) {
  const convertedToMinues = secondsToMinutes(delayInSeconds);
  return Math.abs(convertedToMinues) <= 30;
}

export function getRouteItemsOfTypeBooking(routeItems: RouteItemForServiceWorker[]) {
  return routeItems.filter((routeItem) => routeItem.type === 'booking');
}

export function getRouteSummary(routeItems: RouteItemForServiceWorker[]) {
  const onlyBookingRouteItems = getRouteItemsOfTypeBooking(routeItems);
  const countByDescription = onlyBookingRouteItems
    .flatMap((item) => item.booking.bookingItems.flatMap((bookingItem) => bookingItem.orderLineDescriptions))
    .reduce(
      (acc, description) => {
        acc[description] = (acc[description] ?? 0) + 1;
        return acc;
      },
      {} as Record<string, number>
    );
  return countByDescription;
}
