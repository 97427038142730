import { Box } from '@mui/material';
import { Marker } from 'react-map-gl';

import { colors } from '../../../../../tailwind-design-preset';
import CarSvg from '../../SVG/car';
import HouseSvg from '../../SVG/house';
import PinSvg from '../../SVG/pin';
import { MarkerProps } from '../interface';

type Props = {
  marker: MarkerProps;
  currentActiveMarkerId?: string | number;
  setCurrentActiveMarkerId?: (id: string | number) => void;
};

const NoddiMapMarker = ({ marker, currentActiveMarkerId, setCurrentActiveMarkerId }: Props) => {
  const getMarkerIcon = () => {
    switch (marker.type) {
      case 'car':
        return <CarSvg height={40} width={40} fill={marker?.color ?? colors.primary.darkPurple} />;
      case 'house':
        return <HouseSvg height={40} width={40} fill={marker?.color ?? colors.primary.darkPurple} />;
      case 'default':
        return <PinSvg height={40} width={40} fill={marker?.color ?? colors.primary.darkPurple} />;
      case 'number':
        return (
          <div
            className={`flex size-6 items-center justify-center rounded-full text-primary-white ${marker.color || 'bg-primary-darkPurple'}`}
          >
            {marker.markerText}
          </div>
        );

      default:
        return <PinSvg height={40} width={40} fill={marker?.color ?? colors.primary.darkPurple} />;
    }
  };
  return (
    <Marker
      key={marker.id}
      latitude={marker.latitude}
      longitude={marker.longitude}
      onClick={() => setCurrentActiveMarkerId?.(marker.id)}
    >
      <Box
        sx={{
          ...(marker.id === currentActiveMarkerId && {
            filter: () => `drop-shadow(0px 0px 8px ${colors.primary.darkPurple})`
          })
        }}
      >
        {getMarkerIcon()}
      </Box>
    </Marker>
  );
};

export default NoddiMapMarker;
