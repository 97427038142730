import { PickingList } from 'noddi-ui';

import HomeContentWrapper from '../../layouts/HomeContentWrapper';

export const PickingListWorkerApp = () => {
  return (
    <HomeContentWrapper title='Picking list'>
      <PickingList />
    </HomeContentWrapper>
  );
};
