/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, FieldInputProps, FormikProps } from 'formik';
import { AddressProps } from 'noddi-async/src/types';

import { commonTexts } from '../../../../../commonTexts';
import { AddressPicker, AddressPickerProps } from '../../../../Domains/Address/AddressPicker';

export type FormAddressPickerProps = Omit<AddressPickerProps, 'onSelectAddress'> & {
  name: string;
  label: string;
  customOnSelectAddress?: (address: AddressProps | null) => void;
  translations?: {
    couldNotFindAddress: string;
    addressNotFound: string;
    tryAgainWithStreetNumber: string;
    chooseFromAnotherAddress: string;
    chooseFromYourAddresses: string;
  };
};

const NoddiFormAddressPicker = ({
  name,
  label,
  customOnSelectAddress,
  translations,
  ...rest
}: FormAddressPickerProps) => {
  const texts = translations ?? commonTexts.addressTranslations;

  return (
    <Field name={name}>
      {({ field, form }: { field: FieldInputProps<string | number>; form: FormikProps<any> }) => (
        <div>
          <label htmlFor={name}>{label}</label>
          <AddressPicker
            translations={texts}
            initWithCustomSearch
            hideBottomOptions
            onSelectAddress={(address) => {
              if (customOnSelectAddress) {
                customOnSelectAddress(address);
              } else {
                form.setFieldValue(name, address);
              }
            }}
            {...field}
            {...rest}
          />
        </div>
      )}
    </Field>
  );
};
export { NoddiFormAddressPicker };
