import { IconSvgProps } from './svgtype';

const User = ({ color, ...props }: IconSvgProps) => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <circle cx={16} cy={10.159} r={4.267} stroke={color} strokeWidth={2} />
    <path
      stroke={color}
      strokeWidth={2}
      d='M23.467 21.693c0 5.886-14.934 5.886-14.934 0 0-2.357 3.343-4.267 7.467-4.267s7.467 1.91 7.467 4.267Z'
    />
  </svg>
);
export { User };
