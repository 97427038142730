import { IconSvgProps } from './svgtype';

const Star = ({ color, ...props }: IconSvgProps) => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      stroke={color}
      strokeWidth={1.5}
      d='M12.868 8.75C14.262 6.25 14.958 5 16 5c1.041 0 1.738 1.25 3.131 3.75l.361.646c.396.71.594 1.065.903 1.3.308.234.693.32 1.462.495l.7.158c2.705.612 4.058.918 4.38 1.954.322 1.034-.6 2.113-2.445 4.27l-.477.558c-.524.613-.787.92-.904 1.299-.118.379-.079.788 0 1.605l.073.745c.278 2.878.418 4.317-.425 4.956-.842.64-2.109.057-4.642-1.11l-.656-.302c-.72-.33-1.08-.497-1.461-.497-.382 0-.742.166-1.461.497l-.656.302c-2.533 1.167-3.8 1.75-4.642 1.11-.843-.64-.704-2.078-.425-4.956l.072-.745c.08-.817.12-1.226.001-1.605-.117-.38-.38-.686-.904-1.3l-.477-.557c-1.844-2.157-2.767-3.236-2.445-4.27.322-1.036 1.675-1.342 4.38-1.954l.7-.158c.77-.174 1.154-.261 1.462-.495.31-.235.507-.59.903-1.3l.36-.647Z'
    />
  </svg>
);

export { Star };
