import { URLKeys, noddiAsync } from 'noddi-async';
import { NoddiAsyncError, RouteForServiceWorker } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { NoddiButton, NoddiDialog, NoddiIcon, colors, errorCodes, useNoddiToast } from 'noddi-ui';
import { DateFormats, differenceBetweenDates, format, isToday } from 'noddi-util';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../../appRoutes';
import RouteCardItemDetails from './RouteCardItemDetails';
import RoutePaymentDetails from './RoutePaymentDetails';

export interface RouteCardProps {
  route: RouteForServiceWorker;
}

export default function RouteCard({ route }: RouteCardProps) {
  const [openStartRouteModal, setOpenStartRouteModal] = useState(false);
  const [openConfirmRouteModal, setOpenConfirmRouteModal] = useState(false);
  const [isPaymentDetailsOpen, setIsPaymentDetailsOpen] = useState(false);

  const navigate = useNavigate();
  const { noddiToast } = useNoddiToast();

  const { mutateAsync: mutateStartRoute, isPending: isStartRouteLoading } = noddiAsync.usePost({
    type: URLKeys.postStartRouteForServiceWorker,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({ urlKey: URLKeys.getRoutesForServiceWorker });
        setOpenStartRouteModal(false);
        navigate(routes.todaysAppointments.getPath({ routeId: route.id }));
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.asyncError(error, errorCodes);
      }
    }
  });

  const { mutateAsync: mutateConfirmRoute, isPending: isConfirmRouteLoading } = noddiAsync.usePost({
    type: URLKeys.postConfirmRouteForServiceWorker,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({ urlKey: URLKeys.getRoutesForServiceWorker });
        setOpenStartRouteModal(false);
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.asyncError(error, errorCodes);
      }
    }
  });

  const routeTime = `${format(route.startDatetime, DateFormats.TIME)} - ${format(route.endDatetime, DateFormats.TIME)}`;
  const routeTimeMinutes = Math.floor(differenceBetweenDates(route.endDatetime, route.startDatetime, 'minutes'));
  const numberOfCustomers = route.numberOfStops;

  const numberOfCustomersText =
    numberOfCustomers === 1 ? `${numberOfCustomers} customer` : `${numberOfCustomers} customers`;

  const numberOfCars = route.numberOfCars;

  const numberOfCarsText = numberOfCars === 1 ? `${numberOfCars} car` : `${numberOfCars} cars`;
  const hours = Math.floor(routeTimeMinutes / 60);
  const minutes = routeTimeMinutes % 60;
  const totalRouteText = `${hours}h ${minutes}m`;

  const handlePaymentDetailsClick = () => {
    if (route.completedAt) {
      navigate(routes.profile.getPath());
      return;
    }
    setIsPaymentDetailsOpen((prev) => !prev);
  };

  return (
    <div className='rounded-lg bg-primary-white px-4 py-3'>
      <div className='flex w-full flex-col justify-between'>
        <div className='flex flex-col gap-1'>
          {route.completedAt && (
            <div className='flex items-center gap-2'>
              <NoddiIcon color={colors.signal.success} name='Check' />
              <p className='text-3 text-signal-success'>Route completed</p>
            </div>
          )}
          <div className='flex items-center gap-2'>
            <NoddiIcon name='Calendar' />
            <p className='text-3'>{format(route.date, DateFormats.FULL_DAY_MONTH)}</p>
          </div>
          <div className='flex items-center gap-2'>
            <NoddiIcon name='ClockCircle' />
            <p className='text-3'>
              {routeTime}, {totalRouteText}
            </p>
          </div>
          <div className='flex items-center gap-[5px]'>
            <NoddiIcon className='ml-[2.5px]' name='Car' />
            <p className='text-3'>{Math.floor(route.numberOfMeters / 1000)} km</p>
          </div>

          <div className='flex items-center gap-2'>
            <NoddiIcon name='User' />
            <p className='text-3'>
              {numberOfCustomersText}, {numberOfCarsText}
            </p>
          </div>

          <NoddiButton startIcon='Bill' className='p-0 !text-3' variant='link' onClick={handlePaymentDetailsClick}>
            See payment details
          </NoddiButton>
        </div>

        <div className='mb-4 mt-1'>
          <RouteCardItemDetails routeId={route.id} />
        </div>

        <div className='flex flex-col gap-3'>
          {route.startedAt && !route.completedAt && (
            <NoddiButton
              fullWidth
              variant='secondary'
              size='small'
              onClick={() => navigate(routes.todaysAppointments.getPath({ routeId: route.id }))}
            >
              Go to route
            </NoddiButton>
          )}
          <div className='flex gap-2'>
            {!route?.confirmedAt && (
              <NoddiButton
                fullWidth
                size='small'
                variant='secondary'
                onClick={() => setOpenConfirmRouteModal((prev) => !prev)}
              >
                Confirm route
              </NoddiButton>
            )}
          </div>
          {!route.startedAt && isToday(route.date) && (
            <NoddiButton
              size='small'
              endIcon='ArrowRight'
              fullWidth
              onClick={() => setOpenStartRouteModal((prev) => !prev)}
            >
              Start route
            </NoddiButton>
          )}
        </div>
      </div>
      <NoddiDialog onClose={() => setIsPaymentDetailsOpen(false)} open={isPaymentDetailsOpen} title='Payment details'>
        <RoutePaymentDetails routeId={route.id} />
      </NoddiDialog>

      <NoddiDialog onClose={() => setOpenStartRouteModal(false)} open={openStartRouteModal} title='Start this route?'>
        <div>
          <p>Are you sure you want to start this route?</p>
          <div className='mt-6 flex justify-end gap-4'>
            <NoddiButton onClick={() => setOpenStartRouteModal(false)} variant='secondary'>
              Cancel
            </NoddiButton>
            <NoddiButton
              loading={isStartRouteLoading}
              onClick={async () => {
                await mutateStartRoute({ routeId: route.id });
              }}
            >
              Start route
            </NoddiButton>
          </div>
        </div>
      </NoddiDialog>
      <NoddiDialog
        open={openConfirmRouteModal}
        onClose={() => setOpenConfirmRouteModal(false)}
        title='Confirm this route?'
      >
        <div>
          <p>Are you sure you want to confirm this route?</p>
          <div className='mt-6 flex justify-end gap-4'>
            <NoddiButton onClick={() => setOpenConfirmRouteModal(false)} variant='secondary'>
              Cancel
            </NoddiButton>
            <NoddiButton
              loading={isConfirmRouteLoading}
              onClick={async () => {
                await mutateConfirmRoute({ routeId: route.id });
              }}
            >
              Confirm route
            </NoddiButton>
          </div>
        </div>
      </NoddiDialog>
    </div>
  );
}
