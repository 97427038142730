import styled from 'styled-components';

import { colors } from '../../../../tailwind-design-preset';

type Size = 'small' | 'medium' | 'large';

type Props = {
  size?: Size;
  hoverColor?: keyof typeof colors;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Cross = ({ size }: { size: Size }) => {
  let crossSize;
  switch (size) {
    case 'small':
      crossSize = 15;
      break;
    case 'medium':
      crossSize = 25;
      break;
    case 'large':
      crossSize = 35;
      break;
    default:
      crossSize = 15;
  }
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={crossSize} height={crossSize}>
      <path fill='none' stroke='#000' strokeWidth='2' d='M4,4 L20,20 M20,4 L4,20' />
    </svg>
  );
};

const StyledButton = styled.button<{ size: Size }>`
  position: absolute;
  top: 15px;
  right: 25px;
  background-color: transparent;
  padding: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #cebfdb;
  }
  @media (max-width: 520px) {
    right: 15px;
  }
`;

const CloseCrossButton = ({ size = 'small', ...rest }: Props) => {
  return (
    <StyledButton size={size} {...rest}>
      <Cross size={size} />
    </StyledButton>
  );
};

export { CloseCrossButton };
