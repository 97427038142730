import { IconSvgProps } from './svgtype';

const ChatRounded = ({ color, ...props }: IconSvgProps) => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <path
      stroke={color}
      strokeWidth={1.5}
      d='M16 26.667c5.891 0 10.667-4.776 10.667-10.667S21.89 5.333 16 5.333 5.333 10.11 5.333 16c0 1.706.401 3.32 1.113 4.75.19.38.253.814.143 1.224l-.635 2.375a1.387 1.387 0 0 0 1.698 1.697l2.374-.635c.41-.11.845-.047 1.225.143A10.622 10.622 0 0 0 16 26.667Z'
    />
    <path stroke={color} strokeLinecap='round' strokeWidth={1.5} d='M11.733 14.4h8.534M11.733 18.133H17.6' />
  </svg>
);
export { ChatRounded };
