import { IconSvgProps } from './svgtype';

const Info = ({ color, ...props }: IconSvgProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15 20.75C15.4142 20.75 15.75 20.4142 15.75 20V14C15.75 13.5858 15.4142 13.25 15 13.25C14.5858 13.25 14.25 13.5858 14.25 14V20C14.25 20.4142 14.5858 20.75 15 20.75Z'
      fill={color}
    />
    <path
      d='M15 10C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12C14.4477 12 14 11.5523 14 11C14 10.4477 14.4477 10 15 10Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.25 15C4.25 9.06294 9.06294 4.25 15 4.25C20.9371 4.25 25.75 9.06294 25.75 15C25.75 20.9371 20.9371 25.75 15 25.75C9.06294 25.75 4.25 20.9371 4.25 15ZM15 5.75C9.89137 5.75 5.75 9.89137 5.75 15C5.75 20.1086 9.89137 24.25 15 24.25C20.1086 24.25 24.25 20.1086 24.25 15C24.25 9.89137 20.1086 5.75 15 5.75Z'
      fill={color}
    />
  </svg>
);

export { Info };
