import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { NoddiBasicCard, NoddiIcon } from 'noddi-ui';
import { differenceBetweenDates } from 'noddi-util';

import { getRouteItemsOfTypeBooking, getRouteSummary } from '../../../utils/routeItem';
import RouteDetailItem from './RouteDetailItem';

interface RouteDetailsProps {
  routeItems: RouteItemForServiceWorker[];
  onNavigateToRouteItem?: (routeItemId: number) => void;
}

const RouteOverview = ({ routeItems, onNavigateToRouteItem }: RouteDetailsProps) => {
  const onlyBookings = getRouteItemsOfTypeBooking(routeItems);
  const routeSummary = getRouteSummary(routeItems);

  return (
    <div className='flex flex-col gap-2'>
      <NoddiBasicCard className='border'>
        <div className='flex items-center gap-1'>
          <NoddiIcon name='Bill' />
          <h2 className='text-5 font-semibold'>Summary</h2>
        </div>
        <div className='mt-2'>
          {Object.entries(routeSummary).map(([description, count]) => (
            <p key={description}>
              {description}: {count}
            </p>
          ))}
        </div>
      </NoddiBasicCard>

      {routeItems.map((routeItem, index) => {
        switch (routeItem.type) {
          case 'booking':
            return (
              <NoddiBasicCard className='bg-systemColors-purpleBg' key={routeItem.id}>
                <RouteDetailItem
                  key={routeItem.id}
                  routeItem={routeItem}
                  index={onlyBookings.findIndex((currentRouteItem) => currentRouteItem.id === routeItem.id)}
                  onNavigateToRouteItem={onNavigateToRouteItem}
                />
              </NoddiBasicCard>
            );
          case 'break':
            return (
              <NoddiBasicCard className='border bg-systemColors-raspberryBg' key={routeItem.id}>
                <div className='flex items-center gap-4'>
                  <NoddiIcon name='Pause' />
                  <p>
                    Break -{' '}
                    {Math.abs(
                      differenceBetweenDates(routeItem.estimatedServiceStart, routeItem.estimatedDeparture, 'minutes')
                    )}{' '}
                    minutes
                  </p>
                </div>
              </NoddiBasicCard>
            );
          case 'depot':
            return (
              <NoddiBasicCard className='border bg-systemColors-raspberryBg' key={routeItem.id}>
                <div className='flex items-center gap-4'>
                  <NoddiIcon name='Home' />
                  <p>
                    {index === 0 ? 'Start' : 'Finish'} - {routeItem.address.streetName} {routeItem.address.streetNumber}
                  </p>
                </div>
              </NoddiBasicCard>
            );

          default:
            return null;
        }
      })}
    </div>
  );
};

export default RouteOverview;
