export const getCarDisplayName = (carProps: {
  make?: string | null;
  model?: string | null;
  licensePlateNumber?: string | null;
  makeName?: string | null;
}) => {
  let carText = '';

  if ('make' in carProps) {
    carText += carProps.make;
  }
  if ('makeName' in carProps) {
    carText += carProps.makeName;
  }
  if ('model' in carProps) {
    carText += ` ${carProps.model}`;
  }

  if ('licensePlateNumber' in carProps) {
    carText += ` - ${carProps.licensePlateNumber}`;
  }

  return carText;
};

export const psiToBar = (psi: number) => psi * 0.0689476;
