import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

const NoddiTextInput = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const { label, name, variant = 'outlined' } = props;

  const labelIdentifier = `${name}_label`;

  return (
    <div className='flex h-full flex-col justify-between'>
      <label id={labelIdentifier} htmlFor={name}>
        {label}
      </label>
      <TextField
        data-testid='noddi-text-input'
        fullWidth
        variant={variant}
        {...props}
        inputProps={{
          ...props.inputProps,
          'aria-labelledby': labelIdentifier
        }}
        sx={{
          '& .MuiOutlinedInput-input': {
            borderRadius: 2
          }
        }}
        inputRef={ref}
        label={undefined}
        onWheel={(event) => {
          const target = event.target as HTMLInputElement;
          target.blur();
        }}
      />
    </div>
  );
});

export { NoddiTextInput };
