/// <reference types="@types/google.maps" />

import GeocoderResult = google.maps.GeocoderResult;
import GeocoderStatus = google.maps.GeocoderStatus;

export const geocodeByAddress = (address: string) => {
  const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results: GeocoderResult[] | null, status: GeocoderStatus) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        reject(
          new Error(`Geocoding query for a place with an address: '${address}' failed - response status: ${status}`)
        );
        return;
      }
      resolve(results);
    });
  });
};
