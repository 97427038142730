import { CarDetailsProps, WheelProps } from 'noddi-async/src/types';
import styled from 'styled-components';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { TireType, Tires, WheelSetStatus, WheelSetStatusType } from './types';

export enum WheelPosition {
  FrontRight = 0,
  FrontLeft = 1,
  RearRight = 2,
  RearLeft = 3
}

export const limitSummerTires = 1.6;
export const limitWinterTires = 3.0;
export const recommendedSummerTires = 3.0;
export const recommendedWinterTires = 4.5;

export const resolveWheelMeasurement = (car: CarDetailsProps) => {
  const { wheelSets } = car;

  const summer = wheelSets.find((x) => x.type.label.toLowerCase() === 'summer');
  const winter = wheelSets.find((x) => x.type.label.toLowerCase() === 'winter');

  return { summer, winter };
};

export const calculateWheelSetStatus = (car: CarDetailsProps, type: TireType) => {
  const wheelSets = resolveWheelMeasurement(car);
  const wheelSet = wheelSets[type];

  if (!wheelSet) {
    return WheelSetStatus.MissingMeasurements;
  }
  if (!wheelSet.wheels || wheelSet.wheels.length === 0) {
    return WheelSetStatus.MissingMeasurements;
  }
  if (wheelSet.wheels.every((x) => !x.measurements || x.measurements.length === 0)) {
    return WheelSetStatus.MissingMeasurements;
  }

  const statuses: WheelSetStatusType[] = [];

  Object.entries(WheelPosition).forEach((_, position) => {
    statuses.push(calculateWheelStatus(wheelSet.wheels, position, type));
  });

  // the wheel set status is the worst status of all the wheels
  if (statuses.some((x) => x === WheelSetStatus.WornOut)) {
    return WheelSetStatus.WornOut;
  }
  if (statuses.some((x) => x === WheelSetStatus.SoonWornOut)) {
    return WheelSetStatus.SoonWornOut;
  }
  return WheelSetStatus.Satisfactory;
};

export const calculateWheelStatus = (wheels: WheelProps[], position: WheelPosition, type: TireType) => {
  const lastMeasurement = findLatestWheelMeasurement(wheels, position);

  if (!lastMeasurement) {
    return WheelSetStatus.MissingMeasurements;
  }

  const { value } = lastMeasurement;

  if (type === Tires.summer) {
    if (value < limitSummerTires) {
      return WheelSetStatus.WornOut;
    }
    if (value <= recommendedSummerTires) {
      return WheelSetStatus.SoonWornOut;
    }
  }

  if (type === Tires.winter) {
    if (value < limitWinterTires) {
      return WheelSetStatus.WornOut;
    }
    if (value <= recommendedWinterTires) {
      return WheelSetStatus.SoonWornOut;
    }
  }

  return WheelSetStatus.Satisfactory;
};

export const findLatestWheelMeasurement = (wheels: WheelProps[], position: WheelPosition) => {
  const measurements = wheels.find((x) => x.position.value === position)?.measurements;
  if (!measurements || measurements.length === 0) {
    return null;
  }

  // return latest measurement in time
  return measurements.reduce((prev, current) =>
    new Date(prev.measuredAt) > new Date(current.measuredAt) ? prev : current
  );
};

const CheckIconBackground = styled('div')(() => ({
  color: '#fff',
  width: 17,
  height: 17,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'green'
}));

export const wheelSetStatusToTranslation = (
  wheelSetStatus: WheelSetStatusType,
  translations: {
    missingMeasurements: string;
    inAGoodCondition: string;
    soonWornOut: string;
    wornOut: string;
  }
) => {
  switch (wheelSetStatus) {
    case WheelSetStatus.MissingMeasurements:
      return translations.missingMeasurements;
    case WheelSetStatus.Satisfactory:
      return translations.inAGoodCondition;
    case WheelSetStatus.SoonWornOut:
      return translations.soonWornOut;
    case WheelSetStatus.WornOut:
      return translations.wornOut;
  }
};

export const resolveWheelSetStatusLogo = (status: WheelSetStatusType) => {
  switch (status) {
    case WheelSetStatus.Satisfactory:
      return (
        <CheckIconBackground>
          <NoddiIcon name='Check' size='medium' color={colors.primary.white} />
        </CheckIconBackground>
      );
    case WheelSetStatus.SoonWornOut:
      return <NoddiIcon name='Warning' color={colors.signal.warning} size='medium' />;
    case WheelSetStatus.WornOut:
      return <NoddiIcon name='Warning' color={colors.signal.danger} size='medium' />;
    case WheelSetStatus.MissingMeasurements:
      return null;
    default:
      return <NoddiIcon name='Warning' color={colors.signal.danger} size='medium' />;
  }
};
