import { useState } from 'react';

import { commonTexts } from '../../../commonTexts';
import { NoddiButton, NoddiFormAddressPicker, NoddiFormSelect } from '../../Elements';

interface CapacityContributionAddressesProps {
  addressesOptions: { label: string; value: number | undefined }[];
  defaultAddress: number | string;
  translations?: {
    couldNotFindAddress: string;
    addressNotFound: string;
    tryAgainWithStreetNumber: string;
    chooseFromAnotherAddress: string;
    chooseFromYourAddresses: string;
    enterStartAddress: string;
    enterEndAddress: string;
    selectStartAddress: string;
    selectEndAddress: string;
    chooseFromExistingAddress: string;
    chooseNewStartAddress: string;
    chooseNewEndAddress: string;
  };
}

export function CapacityContributionAddresses({
  addressesOptions,
  defaultAddress,
  translations
}: CapacityContributionAddressesProps) {
  const [isCustomStartAddress, setIsCustomStartAddress] = useState(false);
  const [isCustomEndAddress, setIsCustomEndAddress] = useState(false);

  const texts = translations ?? {
    ...commonTexts.capacityContributionAddresses,
    ...commonTexts.addressTranslations
  };

  return (
    <div className='flex flex-col gap-2'>
      <div>
        {isCustomStartAddress ? (
          <NoddiFormAddressPicker
            name='startAddress'
            label={texts.enterStartAddress}
            addresses={[]}
            translations={texts}
          />
        ) : (
          <NoddiFormSelect
            name='startAddress'
            label={texts.selectStartAddress}
            options={addressesOptions}
            value={defaultAddress}
            fullWidth
          />
        )}
        <NoddiButton onClick={() => setIsCustomStartAddress((prev) => !prev)} className='pl-0' variant='link'>
          {isCustomStartAddress ? texts.chooseFromExistingAddress : texts.chooseNewStartAddress}
        </NoddiButton>
      </div>

      <div>
        {isCustomEndAddress ? (
          <NoddiFormAddressPicker name='endAddress' label={texts.enterEndAddress} addresses={[]} translations={texts} />
        ) : (
          <NoddiFormSelect
            name='endAddress'
            label={texts.selectEndAddress}
            options={addressesOptions}
            value={defaultAddress}
            fullWidth
          />
        )}
        <NoddiButton onClick={() => setIsCustomEndAddress((prev) => !prev)} className='pl-0' variant='link'>
          {isCustomEndAddress ? texts.chooseFromExistingAddress : texts.chooseNewEndAddress}
        </NoddiButton>
      </div>
    </div>
  );
}
