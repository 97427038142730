import { Typography } from '@mui/material';
import styled from 'styled-components';

import { colors } from '../../../../tailwind-design-preset';

type QuestionMarkProps = {
  color?: string;
  backgroundColor?: string;
  fontSize?: number;
  backgroundSizePixel?: number;
};

type CircleProps = {
  backgroundColor: string;
  backgroundSize: number;
  color: string;
};

const Circle = styled.div<CircleProps>`
  width: ${(props) => props.backgroundSize}px;
  height: ${(props) => props.backgroundSize}px;
  border-radius: 50%;
  padding: 3px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
`;

export const QuestionMark = ({
  color = 'white',
  fontSize = 14,
  backgroundSizePixel = 14,
  backgroundColor = colors.primary.orange
}: QuestionMarkProps) => {
  return (
    <Circle backgroundColor={backgroundColor} backgroundSize={backgroundSizePixel} color={color}>
      <Typography fontSize={fontSize}>?</Typography>
    </Circle>
  );
};
