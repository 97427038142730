import { IconSvgProps } from './svgtype';

const ShoppingCart = ({ color, ...props }: IconSvgProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.24896 5.29245C4.8582 5.15506 4.43005 5.36047 4.29266 5.75123C4.15527 6.142 4.36068 6.57015 4.75145 6.70754L5.01266 6.79937C5.68026 7.03409 6.11902 7.18964 6.44186 7.34805C6.74509 7.49683 6.87876 7.61726 6.96682 7.74612C7.05708 7.87821 7.12678 8.05963 7.16611 8.42298C7.20726 8.80319 7.20828 9.2984 7.20828 10.0383V12.76C7.20828 14.2125 7.22191 15.2599 7.35897 16.0601C7.50529 16.9144 7.79742 17.526 8.34366 18.1022C8.93752 18.7285 9.69032 19.0012 10.5866 19.1283C11.4448 19.25 12.5346 19.25 13.8804 19.25L19.2861 19.25C20.0278 19.25 20.6518 19.25 21.1568 19.1882C21.6925 19.1227 22.1811 18.9793 22.6076 18.6318C23.0341 18.2842 23.2731 17.8346 23.4455 17.3232C23.6079 16.841 23.7339 16.2299 23.8836 15.5035L24.3925 13.0341L24.3935 13.0295L24.4039 12.9773C24.5686 12.1524 24.7071 11.4585 24.7416 10.9004C24.7777 10.3142 24.711 9.73616 24.3292 9.23977C24.0942 8.93435 23.7639 8.76144 23.4634 8.65586C23.1569 8.54817 22.8103 8.48587 22.4606 8.44677C21.7735 8.36997 20.9389 8.36998 20.1203 8.36999L8.66809 8.36999C8.6648 8.33324 8.66124 8.29709 8.6574 8.26156C8.60367 7.76518 8.48725 7.31246 8.20527 6.89982C7.92109 6.48396 7.54324 6.21762 7.10261 6.00142C6.69052 5.79922 6.16689 5.61514 5.55036 5.39841L5.24896 5.29245ZM8.70828 9.86999H20.089C20.9454 9.86999 21.6991 9.87099 22.2939 9.93748C22.5895 9.97052 22.8107 10.0164 22.9661 10.071C23.0931 10.1157 23.1361 10.1521 23.1423 10.1574C23.1422 10.1573 23.1426 10.1576 23.1423 10.1574C23.2037 10.2388 23.2704 10.3865 23.2444 10.808C23.217 11.2515 23.1005 11.8438 22.9229 12.7337L22.9225 12.7359L22.4237 15.1561C22.2623 15.9389 22.1537 16.4593 22.024 16.8441C21.9009 17.2095 21.7853 17.3669 21.66 17.469C21.5348 17.571 21.3573 17.6525 20.9746 17.6993C20.5714 17.7487 20.0399 17.75 19.2406 17.75H13.9377C12.5209 17.75 11.5378 17.7482 10.7972 17.6432C10.0823 17.5418 9.70473 17.3576 9.43219 17.0701C9.11202 16.7325 8.93933 16.4018 8.83744 15.8069C8.72628 15.1578 8.70828 14.249 8.70828 12.76L8.70828 9.86999Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5002 24.75C9.25756 24.75 8.2502 23.7426 8.2502 22.5C8.2502 21.2573 9.25756 20.25 10.5002 20.25C11.7428 20.25 12.7502 21.2573 12.7502 22.5C12.7502 23.7426 11.7428 24.75 10.5002 24.75ZM9.7502 22.5C9.7502 22.9142 10.086 23.25 10.5002 23.25C10.9144 23.25 11.2502 22.9142 11.2502 22.5C11.2502 22.0858 10.9144 21.75 10.5002 21.75C10.086 21.75 9.7502 22.0858 9.7502 22.5Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.5002 24.7501C18.2576 24.7501 17.2502 23.7427 17.2502 22.5001C17.2502 21.2574 18.2576 20.2501 19.5002 20.2501C20.7428 20.2501 21.7502 21.2574 21.7502 22.5001C21.7502 23.7427 20.7428 24.7501 19.5002 24.7501ZM18.7502 22.5001C18.7502 22.9143 19.086 23.2501 19.5002 23.2501C19.9144 23.2501 20.2502 22.9143 20.2502 22.5001C20.2502 22.0859 19.9144 21.7501 19.5002 21.7501C19.086 21.7501 18.7502 22.0859 18.7502 22.5001Z'
      fill={color}
    />
  </svg>
);

export { ShoppingCart };
