import { IconSvgProps } from './svgtype';

const Sad = ({ color, ...props }: IconSvgProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='15' cy='15' r='10' stroke={color} strokeWidth='1.5' />
    <path
      d='M12 20C12.8504 19.3697 13.8846 19 15 19C16.1154 19 17.1496 19.3697 18 20'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <ellipse cx='18' cy='13.5' rx='1' ry='1.5' fill={color} />
    <ellipse cx='12' cy='13.5' rx='1' ry='1.5' fill={color} />
  </svg>
);

export { Sad };
