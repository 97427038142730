import { NoddiLocalizationProvider } from 'noddi-provider';
import { ToastProvider } from 'noddi-ui';

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <NoddiLocalizationProvider>
      <ToastProvider>{children}</ToastProvider>
    </NoddiLocalizationProvider>
  );
};

export default Providers;
