import { IconSvgProps } from './svgtype';

const HourGlass = ({ color, ...props }: IconSvgProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15 15L12.0423 17.9289C9.11981 20.823 7.65857 22.27 8.06765 23.5185C8.10282 23.6258 8.14649 23.7302 8.19825 23.8307C8.80046 25 10.867 25 15 25C19.133 25 21.1995 25 21.8017 23.8307C21.8535 23.7302 21.8972 23.6258 21.9323 23.5185C22.3414 22.27 20.8802 20.823 17.9577 17.9289L15 15ZM15 15L17.9577 12.0711C20.8802 9.177 22.3414 7.72997 21.9323 6.48149C21.8972 6.37417 21.8535 6.26977 21.8017 6.16926C21.1995 5 19.133 5 15 5C10.867 5 8.80046 5 8.19825 6.16926C8.14649 6.26977 8.10282 6.37417 8.06765 6.48149C7.65857 7.72997 9.11981 9.177 12.0423 12.0711L15 15Z'
      stroke={color}
      strokeWidth='1.5'
    />
  </svg>
);

export { HourGlass };
