import { NoddiFeedbackBox, NoddiFeedbackWrapper } from 'noddi-ui';
import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

type ToastType = 'success' | 'error' | 'warning';

interface Toast {
  id: number;
  type: ToastType;
  heading: string;
  description?: string;
}

interface ToastContextType {
  addToast: (type: ToastType, message: string, description?: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

const ToastProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [toast, setToast] = useState<Toast | null>(null);

  const addToast = useCallback((type: ToastType, heading: string, description?: string) => {
    const id = Date.now();
    setToast({ id, type, heading, description });
    setTimeout(() => {
      setToast(null);
    }, 5000);
  }, []);

  const { id, type, heading, description } = toast || {};

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <NoddiFeedbackWrapper>
        {toast && (
          <NoddiFeedbackBox
            key={id}
            variant={type}
            heading={heading ?? ''}
            description={description}
            onClose={() => {
              setToast(null);
            }}
            className='border-y-0 border-r-0 bg-primary-white shadow-cardShadow'
          />
        )}
      </NoddiFeedbackWrapper>
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export { ToastProvider, useToast };
