import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { ErrorPage, LoadingScreen, getCarDisplayName, useNoddiToast } from 'noddi-ui';
import { useParams } from 'react-router-dom';

import HomeContentWrapper from '../../../layouts/HomeContentWrapper';
import { getWheelSetTypeTranslation } from '../../../utils/translation';
import { WheelsNotStored } from './WheelsNotStored';
import { WheelsStored } from './WheelsStored';

const CarWheelSet = () => {
  const { carWheelSetId } = useParams();
  const { noddiToast } = useNoddiToast();

  if (!carWheelSetId) {
    return <ErrorPage />;
  }

  const {
    data: carWheelSet,
    error: carWheelSetError,
    isPending: isCarWheelSetPending
  } = noddiAsync.useGet({
    type: URLKeys.getCarWheelSetStorageInformation,
    input: { id: parseInt(carWheelSetId) },
    queryConfig: { enabled: !!carWheelSetId }
  });

  const { mutateAsync: pickUpCarWheelSet, isPending: isPickupCarWheelSetPending } = noddiAsync.usePost({
    type: URLKeys.postPickUpCarWheelSet,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getCarWheelSetStorageInformation,
          input: { id: parseInt(carWheelSetId) }
        });
        noddiToast.success('Success');
      },
      onError: async () => {
        noddiToast.error('An error occurred');
      }
    }
  });

  const { mutateAsync: createCarWheelStorageObjects, isPending: isCreateCarWheelStorageObjectsPending } =
    noddiAsync.usePost({
      type: URLKeys.postCarWheelStorageObjects,
      queryConfig: {
        onSuccess: async () => {
          invalidateQueryExactMatch({
            urlKey: URLKeys.getCarWheelSetStorageInformation,
            input: { id: parseInt(carWheelSetId) }
          });
          noddiToast.success('Success');
        },
        onError: async () => {
          noddiToast.error('An error occurred');
        }
      }
    });

  if (isCarWheelSetPending) {
    return <LoadingScreen />;
  }
  if (carWheelSetError) {
    return <ErrorPage apiError={carWheelSetError} />;
  }
  if (!carWheelSet) {
    return <ErrorPage />;
  }

  const firstStorageObject = carWheelSet.wheels[0]?.currentCarWheelStorageObject?.storageObject;

  const pageDescription = `${getCarDisplayName(carWheelSet.car)} - ${getWheelSetTypeTranslation(carWheelSet.type)}`;

  if (!firstStorageObject) {
    return (
      <HomeContentWrapper title={carWheelSet.userGroup.name} subtitle={pageDescription}>
        <WheelsNotStored
          onDeliver={(storageUnitId) => {
            createCarWheelStorageObjects({
              carWheelIds: carWheelSet.wheels.map((wheel) => wheel.id),
              storageUnitId
            });
          }}
          isLoading={isCarWheelSetPending || isCreateCarWheelStorageObjectsPending}
        />
      </HomeContentWrapper>
    );
  }

  const allWheelsAtSameLocation = carWheelSet.wheels.every((wheel) => {
    return wheel.currentCarWheelStorageObject?.storageObject.storageUnit.id === firstStorageObject.storageUnit.id;
  });

  if (!allWheelsAtSameLocation) {
    return <ErrorPage errorMessage='All wheels must be at the same location' />;
  }

  const allWheelsAreStored = carWheelSet.wheels.every(
    (wheel) => wheel.currentCarWheelStorageObject?.storageObject.deliveredAt
  );
  const allWheelsArePickedUp = carWheelSet.wheels.every(
    (wheel) => wheel.currentCarWheelStorageObject?.storageObject.pickedUpAt
  );

  if (!allWheelsAreStored && !allWheelsArePickedUp) {
    return <ErrorPage errorMessage='All wheels must either have state delivered at or picked up at' />;
  }

  return (
    <HomeContentWrapper title={carWheelSet.userGroup.name} subtitle={pageDescription}>
      {allWheelsArePickedUp ? (
        <WheelsNotStored
          storageObject={firstStorageObject}
          onDeliver={(storageUnitId) => {
            createCarWheelStorageObjects({
              carWheelIds: carWheelSet.wheels.map((wheel) => wheel.id),
              storageUnitId
            });
          }}
          isLoading={isCarWheelSetPending || isCreateCarWheelStorageObjectsPending}
        />
      ) : (
        <WheelsStored
          storageObject={firstStorageObject}
          onPickUp={() => {
            pickUpCarWheelSet({ id: carWheelSet.id });
          }}
          isLoading={isCarWheelSetPending || isPickupCarWheelSetPending}
        />
      )}
    </HomeContentWrapper>
  );
};

export default CarWheelSet;
