import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { errorCodes, useNoddiToast } from 'noddi-ui';

import MeasureTiresForm from './MeasureTiresForm';

interface MeasureTiresProps {
  routeItemId: number;
  bookingItemId: number;
  carId: number;
  lastMeasuredAt?: string | null;
}

const MeasureTires = ({ routeItemId, bookingItemId, carId }: MeasureTiresProps) => {
  const { noddiToast } = useNoddiToast();

  const { mutateAsync: addTireMeasurements, isPending: isAddTireMeasurementsPending } = noddiAsync.usePost({
    type: URLKeys.postAddCarTireMeasurements,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getBookingItemsForRoute,
          input: {
            routeItemId
          }
        });
        noddiToast.success('Wheel measurement registered');
      },
      onError: async (error) => {
        noddiToast.asyncError(error, errorCodes);
      }
    }
  });

  return (
    <div>
      <p>Remember to measure two times.</p>
      <MeasureTiresForm
        ctaText='Add measurements'
        carId={carId}
        bookingItemId={bookingItemId}
        addTireMeasurements={addTireMeasurements}
        isAddTireMeasurementsPending={isAddTireMeasurementsPending}
      />
    </div>
  );
};

export default MeasureTires;
