import { IconSvgProps } from './svgtype';

const CarFront = ({ color = '#1C274C', ...props }: IconSvgProps) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M28 10.6667L25.3333 13.3333L23.3333 8.39999C23.1447 7.89511 22.8075 7.45922 22.3663 7.1498C21.925 6.84037 21.4003 6.67192 20.8613 6.66666H11.2C10.6567 6.65418 10.1225 6.80801 9.66903 7.10754C9.21555 7.40708 8.86442 7.83802 8.66267 8.34266L6.66667 13.3333L4 10.6667'
      stroke={color}
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M9.33333 18.6667H9.34666'
      stroke={color}
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path d='M22.6667 18.6667H22.68' stroke={color} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
    <path
      d='M25.3333 13.3333H6.66667C5.19391 13.3333 4 14.5272 4 16V21.3333C4 22.8061 5.19391 24 6.66667 24H25.3333C26.8061 24 28 22.8061 28 21.3333V16C28 14.5272 26.8061 13.3333 25.3333 13.3333Z'
      stroke={color}
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path d='M6.66667 24V26.6667' stroke={color} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M25.3333 24V26.6667' stroke={color} stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
  </svg>
);

export { CarFront };
