import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';

import { colors } from '../../../../tailwind-design-preset';

export function NoddiLinearProgressLoader() {
  return (
    <Box sx={{ width: '100%', marginTop: 2 }}>
      <LinearProgress
        style={{ backgroundColor: colors.primary.purple }}
        sx={{
          '& .MuiLinearProgress-bar': {
            backgroundColor: colors.primary.purple60
          }
        }}
      />
    </Box>
  );
}
