export const getFlagFromCountryCode = (languageCode?: string) => {
  switch (languageCode) {
    case 'en':
      return '🇬🇧';
    case 'no':
      return '🇳🇴';
    case 'se':
      return '🇸🇪 ';
    default:
      return '🌍';
  }
};
