import { AddHome } from '../../svgs/AddHome';
import { Alert } from '../../svgs/Alert';
import { AltArrowDown } from '../../svgs/AltArrowDown';
import { AltArrowLeft } from '../../svgs/AltArrowLeft';
import { AltArrowRight } from '../../svgs/AltArrowRight';
import { AltArrowUp } from '../../svgs/AltArrowUp';
import ArrowSquareRightCorner from '../../svgs/ArrowCircle';
import { ArrowDown } from '../../svgs/ArrowDown';
import { ArrowLeft } from '../../svgs/ArrowLeft';
import { ArrowRight } from '../../svgs/ArrowRight';
import { ArrowUp } from '../../svgs/ArrowUp';
import { BadgePlus } from '../../svgs/BadgePlus';
import { Bill } from '../../svgs/Bill';
import { Bus } from '../../svgs/Bus';
import { Calendar } from '../../svgs/Calendar';
import { CalendarPlus } from '../../svgs/CalendarPlus';
import { Car } from '../../svgs/Car';
import { CarFront } from '../../svgs/CarFront';
import { ChatRounded } from '../../svgs/ChatRounded';
import { Check } from '../../svgs/Check';
import { CheckRounded } from '../../svgs/CheckRounded';
import { CheckedRectangle } from '../../svgs/CheckedRectangle';
import { ClockCircle } from '../../svgs/ClockCircle';
import { CloseRounded } from '../../svgs/CloseRounded';
import { Copy } from '../../svgs/Copy';
import { Cross } from '../../svgs/Cros';
import { Cup } from '../../svgs/Cup';
import { DownArrowAlt } from '../../svgs/DownArrowAlt';
import { Download } from '../../svgs/Download';
import { Drop } from '../../svgs/Drop';
import { Edit } from '../../svgs/Edit';
import { Exclamation } from '../../svgs/Exclamation';
import { Factory } from '../../svgs/Factory';
import { Filter } from '../../svgs/Filter';
import { Globe } from '../../svgs/Globe';
import { HamburgerMenu } from '../../svgs/HamburgerMenu';
import { Hammer } from '../../svgs/Hammer';
import { Heart } from '../../svgs/Heart';
import { Home } from '../../svgs/Home';
import { HourGlass } from '../../svgs/HourGlass';
import { Info } from '../../svgs/Info';
import { LeftArrowAlt } from '../../svgs/LeftArrowAlt';
import { LocationPin } from '../../svgs/LocationPin';
import { Logout } from '../../svgs/Logout';
import { Magnifier } from '../../svgs/Magnifier';
import { MapArrows } from '../../svgs/MapArrows';
import { MenuDots } from '../../svgs/MenuDots';
import { Minus } from '../../svgs/Minus';
import { Money } from '../../svgs/Money';
import { Notes } from '../../svgs/Notes';
import { Pause } from '../../svgs/Pause';
import { Phone } from '../../svgs/Phone';
import { Plus } from '../../svgs/Plus';
import { PlusCircle } from '../../svgs/PlusCircle';
import { QuestionMark } from '../../svgs/QuestionMark';
import { Rectangle } from '../../svgs/Rectangle';
import { RightArrowAlt } from '../../svgs/RightArrowAlt';
import { Sad } from '../../svgs/Sad';
import { Settings } from '../../svgs/Settings';
import { ShoppingCart } from '../../svgs/ShoppingCart';
import { Square } from '../../svgs/Square';
import { Star } from '../../svgs/Star';
import { TrashBin } from '../../svgs/TrashBin';
import { UpArrowAlt } from '../../svgs/UpArrowAlt';
import { User } from '../../svgs/User';
import { UserCircle } from '../../svgs/UserCircle';
import { Users } from '../../svgs/Users';
import { Warning } from '../../svgs/Warning';
import { Wheel } from '../../svgs/Wheel';
import { WheelAngle } from '../../svgs/WheelAngle';
import { WheelChange } from '../../svgs/WheelChange';

export const iconMap = {
  ArrowUp,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  Bill,
  Calendar,
  ChatRounded,
  ClockCircle,
  Check,
  CheckRounded,
  CloseRounded,
  Cross,
  Cup,
  DownArrowAlt,
  Download,
  Edit,
  Exclamation,
  Filter,
  Factory,
  HamburgerMenu,
  Home,
  LeftArrowAlt,
  Magnifier,
  MenuDots,
  Plus,
  QuestionMark,
  RightArrowAlt,
  Settings,
  Star,
  TrashBin,
  UpArrowAlt,
  UserCircle,
  User,
  Users,
  WheelAngle,
  LocationPin,
  ArrowSquareRightCorner,
  Info,
  Phone,
  AddHome,
  AltArrowDown,
  AltArrowLeft,
  AltArrowRight,
  AltArrowUp,
  Warning,
  Car,
  Wheel,
  ShoppingCart,
  Money,
  Copy,
  Square,
  Logout,
  HourGlass,
  Alert,
  Pause,
  CalendarPlus,
  Hammer,
  Bus,
  Minus,
  WheelChange,
  Drop,
  Notes,
  Sad,
  PlusCircle,
  CarFront,
  Heart,
  BadgePlus,
  MapArrows,
  CheckedRectangle,
  Rectangle,
  Globe
};

export type IconName = keyof typeof iconMap;

export const sizeMap = {
  small: 16,
  medium: 24,
  large: 32,
  extraLarge: 48
} as const;

export type IconSize = keyof typeof sizeMap;
