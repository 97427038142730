import { IconSvgProps } from './svgtype';

const Globe = ({ color, ...props }: IconSvgProps) => (
  <svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <circle cx={15} cy={13} r={7} stroke={color} strokeWidth={1.5} />
    <path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={1.5}
      d='M7 19.562A10.287 10.287 0 0 0 14.687 23C20.383 23 25 18.383 25 12.687 25 9.633 23.672 6.888 21.562 5'
    />
    <path
      stroke={color}
      strokeWidth={1.5}
      d='M10 7.553c.585.552 1.79 2.063 1.931 3.684.131 1.515 1.096 2.746 2.569 2.763.566.006 1.139-.418 1.137-1.005 0-.181-.03-.367-.074-.538a1.137 1.137 0 0 1 .062-.79c.457-.978 1.357-1.241 2.07-1.772.316-.236.604-.484.732-.684.35-.553.702-1.658.526-2.211M22 14c-.22.62-.375 2.25-2.855 2.276 0 0-2.195 0-2.854 1.241-.527.993-.22 2.07 0 2.483'
    />
    <path stroke={color} strokeLinecap='round' strokeWidth={1.5} d='M15 25v-2M15 25h-2M17 25h-2' />
  </svg>
);
export { Globe };
