import styled from 'styled-components';

type SimpleResponsiveGridLayoutProps = {
  smBreakpoint?: string;
  mdBreakpoint?: string;
};

export const SimpleResponsiveGridLayout = styled.div<SimpleResponsiveGridLayoutProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${(props) => props.smBreakpoint || '600px'}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${(props) => props.mdBreakpoint || '1100px'}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;
