import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '../../../../../tailwind-design-preset';

const NoddiLink = styled(Link)`
  color: ${colors.primary.orange};
`;

export { NoddiLink };
