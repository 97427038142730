import styled from 'styled-components';

import { commonTexts } from '../../../commonTexts';
import { ContributionSummaryCard } from './RouteDetails/Contribution/ContributionSummaryCard';
import { RouteSwimLane } from './RouteDetails/RouteSwimLane';
import { RoutesOverviewProps, UniqueCapacityContributionsWithRouteItems } from './types';

const SwimLaneContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #ffffff;
  margin-bottom: 8px;
  border-radius: 8px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 8px;
    background-color: transparent;
    border-radius: none;
  }
`;

const RoutesOverview = ({
  route,
  isSelectedSolution,
  isInternalNoddiRoutes = false,
  translations
}: RoutesOverviewProps) => {
  const texts = translations ?? {
    canceled: commonTexts.canceled,
    unableToComplete: commonTexts.unableToComplete,
    startsTooLate: commonTexts.startsTooLate,
    cars: commonTexts.cars,
    break: commonTexts.break,
    thisRouteIsNotValid: commonTexts.thisRouteIsNotValid,
    customerComments: commonTexts.customerComments,
    adminComments: commonTexts.adminComments,
    workerComments: commonTexts.workerComments,
    waitingTime: commonTexts.waitingTime,
    booking: commonTexts.booking
  };
  const groupedByWorker = route.routeItems.reduce((acc, item) => {
    const capacityContribution = item.capacityContribution;

    // add dummy worker if there is no capacityContribution
    if (!capacityContribution) {
      const existingItem = acc.find((accItem) => accItem.id === -1);

      if (existingItem) {
        existingItem.routeItems.push(item);
        return acc;
      }
      acc.push({
        id: -1,
        fullName: 'Dummy worker',
        phoneNumber: 'Dummy phone number',
        routeItems: [item]
      });
      return acc;
    }

    // adds route item to existing worker
    const existingItem = acc.find((accItem) => accItem.id === item?.capacityContribution?.id);
    if (existingItem) {
      existingItem.routeItems.push(item);
    } else {
      const capacityContribution = item.capacityContribution;
      if (!capacityContribution) {
        return acc;
      }

      acc.push({
        id: capacityContribution.id,
        fullName: capacityContribution.fullName,
        phoneNumber: capacityContribution.phoneNumber,
        routeItems: [item]
      });
    }
    return acc;
  }, [] as UniqueCapacityContributionsWithRouteItems[]);

  return groupedByWorker.map((capacityContribution, index) => {
    return (
      <SwimLaneContainer key={index}>
        <ContributionSummaryCard
          key={capacityContribution.id}
          contribution={capacityContribution}
          isSelectedSolution={isSelectedSolution}
          route={route}
          isInternalNoddiRoutes={isInternalNoddiRoutes}
        />
        <RouteSwimLane
          translations={texts}
          key={`${capacityContribution.id}-${route.id}`}
          routeItemsWithContribution={capacityContribution}
          route={route}
          isSelectedSolution={isSelectedSolution}
        />
      </SwimLaneContainer>
    );
  });
};

export { RoutesOverview };
