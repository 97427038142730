import { NoddiAsyncError } from 'noddi-async/src/types/shared/error';
import { useEffect, useRef, useState } from 'react';

import { NoddiButton, NoddiTextInput } from '../../Elements';
import { ApiErrorMessage, ErrorCodes } from '../../Elements/Errors';

const isLocalEvn = import.meta.env.VITE_APP_LOCAL_ENV;

interface VerificationCodeFormProps {
  phoneNumber: string;
  onClick: (params: { code: string; phoneNumber: string }) => void;
  isLoading: boolean;
  verificationSuccess: boolean;
  error: NoddiAsyncError | null;
  translations: {
    enterCode: string;
    confirm: string;
  };
  errorCodes: ErrorCodes;
}

const VerificationCodeForm = ({
  phoneNumber,
  onClick,
  isLoading,
  verificationSuccess,
  error,
  translations,
  errorCodes
}: VerificationCodeFormProps) => {
  const [verificationCode, setVerificationCode] = useState(isLocalEvn ? '123456' : '');

  //Send the login request for login immediately
  useEffect(() => {
    if (isLocalEvn) {
      onClick({ code: verificationCode, phoneNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verificationCodeIsValid = () => {
    return verificationCode.length === 6;
  };

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, [ref]);

  return (
    <div className='flex flex-col gap-3'>
      <NoddiTextInput
        color='primary'
        value={verificationCode}
        onChange={(e) => {
          const newValue = e.target.value;

          if (newValue) {
            setVerificationCode(newValue);
          } else {
            setVerificationCode('');
          }
        }}
        ref={ref}
        FormHelperTextProps={{
          sx: { margin: 0 }
        }}
        inputProps={{
          minLength: 6,
          maxLength: 6,
          inputMode: 'numeric',
          pattern: '[0-9]*'
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (verificationCodeIsValid() && event.key === 'Enter') {
            onClick({ code: verificationCode, phoneNumber });
          }
        }}
        placeholder={translations.enterCode}
      />
      {error && <ApiErrorMessage errorCodes={errorCodes} error={error} />}
      <div className='flex items-center justify-between gap-4'>
        <NoddiButton
          className='ml-auto'
          disabled={!verificationCodeIsValid() || isLoading || verificationSuccess}
          loading={isLoading}
          onClick={() => onClick({ code: verificationCode, phoneNumber })}
        >
          {translations.confirm}
        </NoddiButton>
      </div>
    </div>
  );
};

export { VerificationCodeForm };
