const ARRIVAL_MINUTE_INTERVAL = 5;
const MAX_ARRIVAL_TIME = 60;

export function roundToNearestMinuteInterval(num: number) {
  const ceiledValue = Math.ceil(num / ARRIVAL_MINUTE_INTERVAL) * ARRIVAL_MINUTE_INTERVAL;
  if (ceiledValue < ARRIVAL_MINUTE_INTERVAL) {
    return ARRIVAL_MINUTE_INTERVAL;
  }
  return Math.ceil(num / ARRIVAL_MINUTE_INTERVAL) * ARRIVAL_MINUTE_INTERVAL;
}

export function getReducedArrivalTime(arrivalTime: number) {
  const value = arrivalTime - ARRIVAL_MINUTE_INTERVAL;
  if (value < ARRIVAL_MINUTE_INTERVAL) {
    return;
  }
  return value;
}

export const getIncreasedArrivalTime = (arrivalTime: number) => {
  const value = arrivalTime + ARRIVAL_MINUTE_INTERVAL;
  if (value > MAX_ARRIVAL_TIME) {
    return;
  }
  return value;
};
