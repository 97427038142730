import { LicensePlateNumberData, SalesCar } from 'noddi-async/src/types';
import { ulid } from 'ulid';

export const convertRegisteredCarToSalesCar = (car: LicensePlateNumberData): SalesCar => {
  return {
    ...car,
    index: ulid(),
    salesItems: [],
    selectedSalesItemMap: new Map<number, number>(),
    availableAddons: [],
    salesItemCategories: []
  };
};
