import { ServiceWorkerList } from '../../workerapp';
import { AddressProps } from '../address';
import { LicenseAreaListItem } from '../licenseArea';
import { ServiceWorkerAttributes } from '../serviceWorkerAttributes';
import { PaginatedInput } from '../utils';

export type CapacityContribution = {
  id: number;
  start: string;
  end: string;
  date: string;
  status: CapacityContributionStatus;
};

export type CapacityContributionListItem = CapacityContribution & {
  serviceWorker: ServiceWorkerList;
  licenseArea?: LicenseAreaListItem;
};

export type CapacityContributionDetails = Pick<CapacityContribution, 'id' | 'date'> & {
  serviceWorkerAttributes: ServiceWorkerAttributes;
  serviceWorker: ServiceWorkerList;
  licenseArea: LicenseAreaListItem;
  createdAt: string;
  startAddress: AddressProps;
  endAddress: AddressProps;
  status: CapacityContributionStatus;
};

export interface PaginatedCapacityContributionsInput extends PaginatedInput {
  fromDate?: string;
  toDate?: string;
  statusIds?: number[];
  serviceWorkerIds?: number[];
  licenseAreaIds?: number[];
}

export type CapacityContributioStartAddress = { startAddressId: number } | { startAddress: AddressProps };
export type CapacityContributionEndAddress = { endAddressId: number } | { endAddress: AddressProps };

export type CreateCapacityContributionDto = {
  maxDuration: number;
  licenseAreaId: number;
  serviceWorkerId: number;
  dates: string[];
  startTime: string;
  endTime: string;
} & CapacityContributioStartAddress &
  CapacityContributionEndAddress;

export interface UpdateCapacityContributionDto
  extends Omit<CreateCapacityContributionDto, 'dates' | 'serviceWorkerId'> {
  id: number;
  statusId: number;
  performanceFactor: number;
  priority: number;
  costPerKm: number;
}

export const contributionStatuses = {
  pending: {
    label: 'Pending',
    value: 0
  },
  submitted: {
    label: 'Submitted',
    value: 1
  },
  waitList: {
    label: 'Wait list',
    value: 2
  },
  reserved: {
    label: 'Reserved',
    value: 3
  },
  declined: {
    label: 'Declined',
    value: 4
  },
  withdrawn: {
    label: 'Withdrawn',
    value: 5
  },
  unassigned: {
    label: 'Unassigned',
    value: 6
  },
  assigned: {
    label: 'Assigned',
    value: 7
  }
} as const;

type ContributionStatusKeys = keyof typeof contributionStatuses;
export type CapacityContributionStatus = (typeof contributionStatuses)[ContributionStatusKeys];
export type ContributionStatusValues = (typeof contributionStatuses)[ContributionStatusKeys]['value'];
export type ContributionStatusLabels = (typeof contributionStatuses)[ContributionStatusKeys]['label'];

export type CapacityContributionOverviewInput = {
  fromDate?: string;
  toDate?: string;
  licenseAreaIds?: number[];
};

export type CapacityContributionOverviewItem = {
  id: number;
  date: string;
  licenseArea: {
    id: number;
    name: string;
  };
};
