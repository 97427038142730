import { IconSvgProps } from './svgtype';

const Bus = ({ color, ...props }: IconSvgProps) => (
  <svg width={30} height={30} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7 13C7 9.22876 7 7.34315 8.17157 6.17157C9.34315 5 11.2288 5 15 5C18.7712 5 20.6569 5 21.8284 6.17157C23 7.34315 23 9.22876 23 13V15C23 18.7712 23 20.6569 21.8284 21.8284C20.6569 23 18.7712 23 15 23C11.2288 23 9.34315 23 8.17157 21.8284C7 20.6569 7 18.7712 7 15V13Z'
      stroke={color}
      strokeWidth='1.5'
    />
    <path d='M7 16H23' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M18.5 19H20' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10 19H11.5' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M9 22.5V24C9 24.5523 9.44772 25 10 25H11.5C12.0523 25 12.5 24.5523 12.5 24V23'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21 22.5V24C21 24.5523 20.5523 25 20 25H18.5C17.9477 25 17.5 24.5523 17.5 24V23'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M23 12H24C24.5523 12 25 12.4477 25 13V14C25 14.3148 24.8518 14.6111 24.6 14.8L23 16'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7 12H6C5.44772 12 5 12.4477 5 13V14C5 14.3148 5.14819 14.6111 5.4 14.8L7 16'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M22.5 8H7.5' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
  </svg>
);

export { Bus };
