import { IconSvgProps } from './svgtype';

const AltArrowLeft = ({ color, ...props }: IconSvgProps) => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M19.2 8.53333L12.8 16L19.2 23.4667'
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { AltArrowLeft };
