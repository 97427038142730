import Stack from '@mui/material/Stack';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { SideNavItem } from './SideNavItem';

interface Item {
  disabled?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  onClick?: () => void;
  title: string;
}

const renderItems = ({
  depth = 0,
  items,
  pathname
}: {
  depth?: number;
  items: Item[];
  pathname?: string | null;
}): JSX.Element[] =>
  items.reduce(
    (acc: JSX.Element[], item) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname
      }),
    []
  );

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  pathname
}: {
  acc: JSX.Element[];
  depth: number;
  item: Item;
  pathname?: string | null;
}): Array<JSX.Element> => {
  const checkPath = !!(item.path && pathname);
  const partialMatch = checkPath ? pathname.includes(item.path as string) : false;
  const exactMatch = checkPath ? pathname === item.path : false;

  const hasActiveChild = item.items
    ? item.items.some((childItem) => pathname && pathname.includes(childItem.path as string))
    : false;

  if (item.items) {
    acc.push(
      <SideNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        open={partialMatch || hasActiveChild}
        title={item.title}
      >
        <Stack
          component='ul'
          spacing={0.5}
          sx={{
            listStyle: 'none',
            m: 0,
            p: 0
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname
          })}
        </Stack>
      </SideNavItem>
    );
  } else {
    acc.push(
      <SideNavItem
        active={exactMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        path={item.path}
        title={item.title}
        onClick={item.onClick}
      />
    );
  }

  return acc;
};

interface SideNavSectionProps {
  items?: Item[];
}

export const SideNavSection = (props: SideNavSectionProps) => {
  const { items = [], ...other } = props;
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Stack
      component='ul'
      spacing={0.5}
      sx={{
        listStyle: 'none',
        m: 0,
        p: 0
      }}
      {...other}
    >
      {renderItems({ items, pathname })}
    </Stack>
  );
};
