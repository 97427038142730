import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SalesCar } from 'noddi-async/src/types';
import { NoddiButton, NoddiIcon } from 'noddi-ui';

type Props = {
  carSearchResult: SalesCar | null;
  regNumber: string;
  onAddCar: () => void;
};

const CarSearchResult = ({ carSearchResult, regNumber, onAddCar }: Props) => {
  if (!carSearchResult?.make) {
    return <div style={{ marginTop: 12 }}>Vi fant dessverre ingen bil med reg. nummer: {regNumber}</div>;
  }
  if (!carSearchResult) {
    return null;
  }

  return (
    <Stack
      gap={5}
      padding={1.5}
      style={{
        marginTop: '0.5em',
        backgroundColor: 'rgb(244, 239, 253)',
        borderRadius: '10px'
      }}
    >
      <Stack spacing={2} alignItems='center' flexDirection='row' justifyContent='space-between'>
        <Typography style={{ fontSize: '0.9rem' }}>
          <span
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <NoddiIcon name='Car' size='medium' className='mb-1 mr-2' /> {carSearchResult.make} {carSearchResult.model}
          </span>
        </Typography>
        <NoddiButton className='mt-2' onClick={onAddCar}>
          Add
        </NoddiButton>
      </Stack>
    </Stack>
  );
};

export default CarSearchResult;
