import { IconSvgProps } from './svgtype';

const CalendarPlus = ({ color, ...props }: IconSvgProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M21 19L19 19M19 19L17 19M19 19L19 17M19 19L19 21' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path d='M10 7V5.5' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path d='M20 7V5.5' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path d='M24.5 12H19.625H13.75M5 12H8.875' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path
      d='M17 25H13C9.22876 25 7.34315 25 6.17157 23.8284C5 22.6569 5 20.7712 5 17V15C5 11.2288 5 9.34315 6.17157 8.17157C7.34315 7 9.22876 7 13 7H17C20.7712 7 22.6569 7 23.8284 8.17157C25 9.34315 25 11.2288 25 15V17C25 20.7712 25 22.6569 23.8284 23.8284C23.1752 24.4816 22.3001 24.7706 21 24.8985'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);

export { CalendarPlus };
