import { Stack, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { useIsMobile } from '../../hooks';

interface Props {
  title?: string;
  description?: string;
  node?: ReactNode;
  sx?: SxProps;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export function NoddiHeader({ title, description, node, sx, titleVariant = 'h4' }: Props) {
  const isMobile = useIsMobile();
  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'start' : 'space-between'}
      alignItems={isMobile ? 'flex-start' : 'center'}
      sx={sx}
    >
      <Stack direction='column'>
        {title && <Typography variant={titleVariant}>{title}</Typography>}
        {description && (
          <Typography color='text.secondary' variant='subtitle1' maxWidth='700px'>
            {description}
          </Typography>
        )}
      </Stack>
      <div style={{ marginTop: isMobile ? '1rem ' : '0px' }}>{node}</div>
    </Stack>
  );
}
