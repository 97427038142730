import { IconSvgProps } from './svgtype';

const LocationPin = ({ color, ...props }: IconSvgProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7 13.1433C7 8.64588 10.5817 5 15 5C19.4183 5 23 8.64588 23 13.1433C23 17.6055 20.4467 22.8124 16.4629 24.6744C15.5343 25.1085 14.4657 25.1085 13.5371 24.6744C9.55332 22.8124 7 17.6055 7 13.1433Z'
      stroke={color}
      strokeWidth='1.5'
    />
    <circle cx='15' cy='13' r='3' stroke={color} strokeWidth='1.5' />
  </svg>
);

export { LocationPin };
