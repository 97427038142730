import { RefObject } from 'react';
import styled from 'styled-components';

import { useHover } from '../../../hooks';

type Hover = {
  hover: boolean;
};

const ExpandingArrowContainer = styled.div`
  display: flex;
  position: relative;
  width: 20px;
  margin-left: 5px;
  align-items: center;
`;

const ArrowSvg = styled.svg<Hover>`
  position: absolute;
  width: 16px;
  height: 16px;
  fill: currentColor;
  transition: all;

  transform: ${({ hover }) => (hover ? 'translateX(1px)' : 'none')};
  opacity: ${({ hover }) => (hover ? 0 : 1)};
`;

const SecondArrowSvg = styled(ArrowSvg)`
  opacity: ${({ hover }) => (hover ? 1 : 0)};
`;

type Props = {
  parentRef: RefObject<HTMLElement>;
};

export const ExpandingArrow = ({ parentRef }: Props) => {
  const isHovered = useHover(parentRef);

  return (
    <ExpandingArrowContainer>
      <ArrowSvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' hover={isHovered}>
        <path
          fillRule='evenodd'
          d='M6.22 3.22a.75.75 0 011.06 0l4.25 4.25a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06-1.06L9.94 8 6.22 4.28a.75.75 0 010-1.06z'
        />
      </ArrowSvg>
      <SecondArrowSvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' hover={isHovered}>
        <path
          fillRule='evenodd'
          d='M8.22 2.97a.75.75 0 011.06 0l4.25 4.25a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06-1.06l2.97-2.97H3.75a.75.75 0 010-1.5h7.44L8.22 4.03a.75.75 0 010-1.06z'
        />
      </SecondArrowSvg>
    </ExpandingArrowContainer>
  );
};
